import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillpaymentComplaintSubmenu from "./BillpaymentComplaintSubmenu";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [complaintStatus, setcomplaintStatus] = useState<any>();
    const status = sessionStorage.getItem("complaintStatus");
    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (status) {
            setcomplaintStatus(JSON.parse(status));
        }
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const onSubmit = (data: any) => {
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        console.log(data);
        LoginServices.TrackComplaint(data, config).then((res) => {
            console.log(res);
        });
    };
    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Complaint</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="Complaint-wrapper">
                    <div className="Complaint-Nav">
                        <BillpaymentNav active="complaints" />
                    </div>
                    <div className="Complaint-submenuWrapper">
                        <BillpaymentComplaintSubmenu active={"status"} />
                        <div className="Complaint-status">
                            <h1>Complaint Status </h1>
                            <div className="Complaint-status-items">
                                <div className="Complaint-status-item">
                                    <h3>Complaint Assigned</h3>
                                    <p>:</p>
                                    <h5>
                                        {complaintStatus?.complaintAssigned}
                                    </h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Complainted ID</h3>
                                    <p>:</p>
                                    <h5>{complaintStatus?.complaintId}</h5>
                                </div>
                                <div className="Complaint-status-item">
                                    <h3>Complaint Status</h3>
                                    <p>:</p>
                                    <h5>{complaintStatus?.complaintStatus}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
