import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import '../../App.css';
import "./dashboard.css";

import Navigation from "../../website-partials/Navigation";
import Banner from "../../website-partials/Banner";
import AboutUs from "../../website-partials/AboutUs";
import Values from "../../website-partials/Values";
import HowDoesWork from "../../website-partials/HowDoesWork";
import Testimonial from "../..//website-partials/Testimonial";
import Services from "../../website-partials/Services";
import Footer from "../../website-partials/Footer";
import DashboardRight from "./dashboardRight";
import WalletTransactions from "./walletTransactions";
import DashboardNav from "./dashboardNav";
import LoginServices from "../../services/LoginServices";
import Login from "../Login";
function Dashboard() {
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState<any>();

    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setUserDetails(response?.data?.UserDetails);
                        localStorage.setItem(
                            "isKycDone",
                            response?.data?.UserDetails?.isKYCverified
                        );
                        localStorage.setItem(
                            "payoutChargePercentage_TOne",
                            response?.data?.UserDetails
                                ?.payoutChargePercentage_TOne
                        );
                        localStorage.setItem(
                            "payoutChargePercentage",
                            response?.data?.UserDetails?.payoutChargePercentage
                        );
                    } else {
                    }
                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);

    return (
        <>
            <React.Fragment>
                <div className="main-bg bbm">
                    <div className="bb0">
                        <DashboardNav active={""}></DashboardNav>
                    </div>

                    <div className="bb1">
                        <div style={{ padding: "30px 45px" }}>
                            <div className="d-flex-j-sp">
                                <div>
                                    <h1 className="head-dashboard">
                                        Dashboard
                                    </h1>
                                    <p className="head-hi">
                                        Hi, {userDetails?.firstName}
                                    </p>
                                </div>
                                <div className="marquee">
                                    <p
                                        className="head-hi"
                                        style={{
                                            color: "#6865f0",
                                            marginBottom: "2rem",
                                        }}>
                                        {userDetails?.news}
                                    </p>
                                </div>
                                {/* <div>
                                    <p className='pay-url'>https://paymark.in/pay/{userDetails?.paymentPageId}</p>

                                </div> */}
                            </div>
                            {userId == "1111110" && (
                                <div className="row">
                                    <div className="col-6 blc-section">
                                        <p className="your-blc">
                                            My Wallet Balance
                                        </p>
                                        <p className="blc-rs">
                                            Rs. {userDetails?.walletAmount}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </>
    );
}

export default Dashboard;
