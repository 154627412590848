import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillpaymentComplaintSubmenu from "./BillpaymentComplaintSubmenu";
import Spinner from "../spinner";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const [spinner, setSpinner] = useState(false);
    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm();
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const getstatus = (body: any) => {
        setSpinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        LoginServices.transactionStatus(body, config)
            .then((res) => {
                setSpinner(false);
                if (res.data.Status == 1) {
                    sessionStorage.setItem(
                        "status",
                        JSON.stringify(res.data.TransactionStatus)
                    );
                    navigate(
                        "/dashboard/bill-payments/billpaymentSearchtransactionresult"
                    );
                } else {
                    alert("something went wrong try again later");
                }
                console.log(res);
            })
            .catch((err) => {
                setSpinner(false);
                console.log(err);
                alert("something went wrong try again later");
            });
    };
    const onSubmit = (data: any) => {
        getstatus({ ...data, trackType: "TRANSACTION_ID" });
    };
    const formtwo = (data: any) => {
        let body = { ...data, trackType: "MOBILE_NO" };
        getstatus(body);
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Complaint</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="Complaint-wrapper">
                    <div className="Complaint-Nav">
                        <BillpaymentNav active="complaints" />
                    </div>
                    <div className="Complaint-submenuWrapper">
                        <BillpaymentComplaintSubmenu active={"search"} />
                        <div
                            className="Complaint-formwrapper search-transaction-wrapper"
                            style={{ paddingBottom: "5rem" }}>
                            <h1>Search Transaction </h1>
                            <form
                                className="search-transaction"
                                onSubmit={handleSubmit(onSubmit)}>
                                <div className="Complaint-form-control ">
                                    <label htmlFor=" Transaction Ref Id">
                                        Transaction Ref Id
                                    </label>
                                    <div>
                                        <input
                                            className={`input ${
                                                errors?.transactionReferenceId &&
                                                "inputerror"
                                            }`}
                                            type="text"
                                            {...register(
                                                "transactionReferenceId",
                                                {
                                                    required:
                                                        " Transaction Ref Id is required",
                                                }
                                            )}
                                        />
                                        {errors?.transactionReferenceId && (
                                            <p className="error">
                                                {
                                                    errors
                                                        ?.transactionReferenceId
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        paddingTop: "0rem",
                                        alignSelf: "center",
                                    }}>
                                    <button type="submit">Search</button>
                                </div>
                            </form>
                            <h3 className="or">OR</h3>
                            <form
                                className="search-transaction-form-two"
                                onSubmit={handleSubmit2(formtwo)}>
                                <div className="Complaint-form-control ">
                                    <label htmlFor=" FromDate">From Date</label>
                                    <div
                                        style={{
                                            height: "100%",
                                            minHeight: "5rem",
                                        }}>
                                        <input
                                            className={`input ${
                                                errors2?.fromDate &&
                                                "inputerror"
                                            }`}
                                            type="date"
                                            {...register2("fromDate", {
                                                required:
                                                    " From Date is required",
                                            })}
                                        />

                                        <p className="error">
                                            {errors2?.fromDate?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="Complaint-form-control ">
                                    <label htmlFor=" ToDate">To Date</label>
                                    <div
                                        style={{
                                            height: "100%",
                                            minHeight: "5rem",
                                        }}>
                                        <input
                                            className={`input ${
                                                errors2?.toDate && "inputerror"
                                            }`}
                                            type="date"
                                            {...register2("toDate", {
                                                required:
                                                    " To Date is required",
                                            })}
                                        />
                                        <p className="error">
                                            {errors2?.toDate?.message}
                                        </p>
                                    </div>
                                </div>
                                <div className="Complaint-form-control ">
                                    <label htmlFor=" MobileNumber">
                                        Mobile Number
                                    </label>
                                    <div
                                        style={{
                                            height: "100%",
                                            minHeight: "5rem",
                                        }}>
                                        <input
                                            className={`input ${
                                                errors2?.mobileNumber &&
                                                "inputerror"
                                            }`}
                                            type="number"
                                            {...register2("mobileNumber", {
                                                required:
                                                    " Mobile number is required",
                                            })}
                                        />

                                        <p className="error">
                                            {errors2?.mobileNumber?.message}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        paddingTop: "0rem",
                                        alignSelf: "center",
                                        marginTop: "1.4rem",
                                        height: "100%",
                                        minHeight: "5rem",
                                    }}>
                                    <button type="submit">Search</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
