import React, { useEffect, useState } from "react";
import "./dashboard.css";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Link,
    useLocation,
} from "react-router-dom";
// import ReactWhatsapp from "react-whatsapp";
import { useNavigate } from "react-router-dom";
import LoginServices from "../../services/LoginServices";
import { ImWhatsapp } from "react-icons/im";
import Logo from "../../images/HomePage_Updated/logo.png";
import { RiBillLine } from "react-icons/ri";
import { useStore } from "../../Store";
import { FaCreditCard } from "react-icons/fa";
let DashboardNav = (props: any) => {
    console.log(props);
    const navigate = useNavigate();

    const { userDetails, setUserDetails } = useStore((state: any) => ({
        ...state,
    }));
    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (userId == "undefined") {
            alert("Login to see your dashboard");
            navigate("/");
        }
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            console.log("3242");

            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        const localState = JSON.parse(
                            localStorage.getItem("userdetails") || ""
                        );
                        console.log(localState, response.data.UserDetails);
                        if (
                            !localState ||
                            JSON.stringify(userDetails) !==
                                JSON.stringify(response?.data?.UserDetails)
                        ) {
                            setUserDetails(response?.data?.UserDetails);
                        }
                        localStorage.setItem(
                            "mobilenumber",
                            response?.data?.UserDetails?.mobileNumber
                        );
                        localStorage.setItem(
                            "userdetails",
                            JSON.stringify(response?.data?.UserDetails)
                        );
                        localStorage.setItem(
                            "payoutChargeAmount",
                            response?.data?.UserDetails?.payoutChargeAmount
                        );
                        localStorage.setItem(
                            "instantPayoutsOn",
                            response?.data?.UserDetails?.instantPayoutsOn
                        );
                        localStorage.setItem(
                            "isKycDone",
                            response?.data?.UserDetails?.isKYCverified
                        );
                        localStorage.setItem(
                            "commission",
                            response?.data?.UserDetails
                                ?.walletCommissionPercentage
                        );
                        console.log(activatedPath);

                        // if (activatedPath != "kyc-pending") {
                        //     if (
                        //         response?.data?.UserDetails?.isKYCverified != 1
                        //     ) {
                        //         // alert('Your KYC is yet to verify. Please contact admin.');
                        //         navigate("/dashboard/kyc-pending");
                        //     }
                        // }
                        //
                    } else {
                    }
                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    }, [""]);
    console.log(userDetails);
    const activatedPath = props.active;
    const UserId = localStorage.getItem("userid");
    // const location = useLocation();
    // console.log(location);

    return (
        <>
            <div>
                <div>
                    <div className="hero-header">
                        <div>
                            <img className="pay-mark-logo" src={Logo} alt="" />
                        </div>
                        <div>
                            <div className="d-flex">
                                <a
                                    className="d-flex align-items-center"
                                    style={{
                                        border: "none",
                                        background: "inherit",
                                    }}
                                    href="https://wa.me/9866592901"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <ImWhatsapp color="#25D366" size={25} />
                                </a>
                                {
                                    <div className="d-flex">
                                        <img
                                            className="hero-head-wallet"
                                            src="assets/img/red-wallet.png"
                                            alt=""
                                        />
                                        <span className="hero-head-rs">
                                            Rs.{userDetails?.walletAmount}
                                        </span>
                                    </div>
                                }
                                {/* <img className="hero-head-icons" src="assets/img/red-setting.png" alt="" /> */}
                                <img
                                    className="hero-head-icons"
                                    src="assets/img/user-pic.png"
                                    alt=""
                                    onClick={() => {
                                        navigate("/dashboard/profile");
                                    }}
                                />
                                <img
                                    onClick={() => {
                                        if (
                                            window.confirm(
                                                "Are you sure want to logout?"
                                            )
                                        ) {
                                            localStorage.clear();
                                            navigate("/");
                                        }
                                    }}
                                    className="hero-head-icons"
                                    src="assets/img/red-logout.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="nav-header">
                        <div
                            className={
                                "icon-name-div " +
                                (activatedPath == "" ? "op1" : "op5")
                            }>
                            <img
                                className="menu-icon"
                                src="assets/dashboard-icons/without-fill/dashboard.png"
                                alt=""
                            />
                            <Link to="/dashboard/">Dashboard</Link>
                        </div>
                        {
                            <div
                                className={
                                    "icon-name-div " +
                                    (activatedPath == "deposits"
                                        ? "op1"
                                        : "op5")
                                }>
                                <img
                                    className="menu-icon"
                                    src="assets/dashboard-icons/load-wallet.png"
                                    alt=""
                                />
                                <Link to="/dashboard/deposits">
                                    Load Wallet
                                </Link>
                            </div>
                        }
                        {UserId == "1111111" && (
                            <div
                                className={
                                    "icon-name-div " +
                                    (activatedPath == "cardPayments"
                                        ? "op1"
                                        : "op5")
                                }>
                                <FaCreditCard
                                    color="white"
                                    className="menu-icon"
                                />
                                <Link to="/dashboard/card-payments">
                                    Card Payments
                                </Link>
                            </div>
                        )}
                        {userDetails?.isPayBillsEnabled == 1 &&
                            UserId != "1111111" && (
                                <div
                                    className={
                                        "icon-name-div " +
                                        (activatedPath == "pay-bills"
                                            ? "op1"
                                            : "op5")
                                    }>
                                    <RiBillLine
                                        // className="menu-icon"
                                        color="white"
                                        size={18}
                                    />
                                    <Link to="/dashboard/pay-bills">
                                        Pay Bills
                                    </Link>
                                </div>
                            )}
                        {/*      {UserId!= "1111110" && (
                            <div
                                className={
                                    "icon-name-div " +
                                    (activatedPath == "wallet-transfer"
                                        ? "op1"
                                        : "op5")
                                }>
                                <img
                                    className="menu-icon"
                                    src="assets/dashboard-icons/wallet.png"
                                    alt=""
                                />
                                <Link to="/dashboard/wallet-transfer">
                                    Wallet Transfers
                                </Link>
                            </div>
                        )} */}
                        {/* billPayment */}
                        {userDetails?.isBBPSvisible == 1 && (
                            <div
                                className={
                                    "icon-name-div " +
                                    (activatedPath == "bill-payments"
                                        ? "op1"
                                        : "op5")
                                }>
                                <img
                                    className="menu-icon"
                                    src="assets/dashboard-icons/filled/billPayment.svg"
                                    alt=""
                                />
                                <Link to="/dashboard/bill-payments">
                                    Bill Payments
                                </Link>
                            </div>
                        )}

                        {/* <div className={"icon-name-div " + (activatedPath == 'bill-payments' ? "op1" : 'op5')}>
                            <img className="menu-icon" src='assets/dashboard-icons/bill-payments.png' alt="" />
                            <Link to="/dashboard/">Bill Payments</Link>
                        </div> */}
                        {/* <div className={"icon-name-div " + (activatedPath == 'pay-bills' ? "op1" : 'op5')}>
                            <img className="menu-icon" src='assets/dashboard-icons/payouts.png' alt="" />
                            <Link to="/dashboard/pay-bills">Payouts</Link>
                        </div> */}
                        {
                            <div
                                className={
                                    "icon-name-div " +
                                    (activatedPath == "transactions"
                                        ? "op1"
                                        : "op5")
                                }>
                                <img
                                    className="menu-icon"
                                    src="assets/dashboard-icons/invoice.png"
                                    alt=""
                                />
                                <Link to="/dashboard/transactions">
                                    Wallet Summary
                                </Link>
                            </div>
                        }
                        <div
                            className={
                                "icon-name-div " +
                                (activatedPath == "commissions" ? "op1" : "op5")
                            }>
                            <img
                                className="menu-icon"
                                src="assets/dashboard-icons/invoice.png"
                                alt=""
                            />
                            <Link to="/dashboard/commissions">
                                Refer and Earn
                            </Link>
                        </div>
                        {true ? (
                            <>
                                {userDetails?.isPayBillsEnabled == 1 &&
                                    UserId != "1111111" && (
                                        <div
                                            className={
                                                "icon-name-div " +
                                                (activatedPath == "payouts"
                                                    ? "op1"
                                                    : "op5")
                                            }>
                                            <img
                                                className="menu-icon"
                                                src="assets/dashboard-icons/invoice.png"
                                                alt=""
                                            />
                                            <Link to="/dashboard/payouts">
                                                Payout Statement
                                            </Link>
                                        </div>
                                    )}
                                {/* <div
                                    className={
                                        "icon-name-div " +
                                        (activatedPath == "services"
                                            ? "op1"
                                            : "op5")
                                    }>
                                    <img
                                        className="menu-icon"
                                        src="assets/dashboard-icons/invoice.png"
                                        alt=""
                                    />
                                    <Link to="/dashboard/services">
                                        Services
                                    </Link>
                                </div> */}
                            </>
                        ) : null}

                        {/*  <div
                            className={
                                "icon-name-div " +
                                (activatedPath == "payment-links"
                                    ? "op1"
                                    : "op5")
                            }>
                            <img
                                className="menu-icon"
                                src="assets/dashboard-icons/paper.png"
                                alt=""
                            />
                            <Link to="/dashboard/payment-links">
                                Payment Links
                            </Link>
                        </div> */}
                        <div
                            className={
                                "icon-name-div " +
                                (activatedPath == "kyc" ? "op1" : "op5")
                            }>
                            <img
                                className="menu-icon"
                                src="assets/dashboard-icons/paper.png"
                                alt=""
                            />
                            <Link to="/dashboard/kyc">KYC</Link>
                        </div>
                        {/* <div className={"icon-name-div " + (activatedPath == 'payment-links' ? "op1" : 'op5')}>
                            <img className="menu-icon" src='assets/dashboard-icons/paper.png' alt="" />
                            <Link to="/dashboard/">Payment Links</Link>
                        </div> */}
                    </div>
                </div>

                {/* <ul className="nav-list">
                    <li className={activatedPath == '' ? "selected-menuitem" : ''} >
                        <div className="row">
                            <div className="col-2">

                                <img className="menu-icon" src={activatedPath == '' ? 'assets/dashboard-icons/filled/dashboard.png' : 'assets/dashboard-icons/without-fill/dashboard.png'} alt="" />
                            </div>
                            <div className="col-10">

                                <Link to="/dashboard/">Dashboard</Link>
                            </div>
                        </div>

                    </li>
                    <li className={activatedPath == 'wallet-transfer' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src={activatedPath == 'wallet-transfer' ? 'assets/dashboard-icons/filled/wallet.png' : 'assets/dashboard-icons/without-fill/wallet.png'} alt="" />
                            </div>
                            <div className="col-10">
                                <Link to="/dashboard/wallet-transfer">Wallet Transfers</Link>
                            </div>
                        </div>
                    </li>
                    <li className={activatedPath == 'deposits' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src={activatedPath == 'deposits' ? 'assets/dashboard-icons/filled/deposit.png' : 'assets/dashboard-icons/without-fill/deposit.png'} alt="" />
                            </div>
                            <div className="col-10">
                                <Link to="/dashboard/deposits">Deposit</Link>
                            </div>
                        </div>
                    </li>
                    <li className={activatedPath == 'bbps' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src={activatedPath == 'bbps' ? 'assets/dashboard-icons/filled/bbps.png' : 'assets/dashboard-icons/without-fill/bbps.png'} alt="" />
                            </div>
                            <div className="col-10">
                                <Link to="/dashboard">BBPS</Link>
                            </div>
                        </div>
                    </li>
                    <li className={activatedPath == 'pay-bills' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src={activatedPath == 'pay-bills' ? 'assets/dashboard-icons/filled/pay-bills.png' : 'assets/dashboard-icons/without-fill/pay-bills.png'} alt="" />
                            </div>
                            <div className="col-10">
                                <Link to="/dashboard/pay-bills">Pay Bills</Link>
                            </div>
                        </div>
                    </li>
                    <li className={activatedPath == 'transactions' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src={activatedPath == 'transactions' ? 'assets/dashboard-icons/filled/transactions.png' : 'assets/dashboard-icons/without-fill/transactions.png'} alt="" />
                            </div>
                            <div className="col-10">
                                <Link to="/dashboard/transactions">Transactions </Link>
                            </div>
                        </div>
                    </li>
                    <li className={activatedPath == 'statements' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src={activatedPath == 'statements' ? 'assets/dashboard-icons/filled/statements.png' : 'assets/dashboard-icons/without-fill/statements.png'} alt="" />
                            </div>
                            <div className="col-10">
                                <Link to="/dashboard">Statements</Link>
                            </div>
                        </div>
                    </li>
                    {
                        userDetails?.isKYCverified == 0 &&
                        <li className={activatedPath == 'kyc' ? "selected-menuitem" : ''}>
                            <div className="row">
                                <div className="col-2">
                                    <img className="menu-icon" src={activatedPath == 'kyc' ? 'assets/dashboard-icons/filled/statements.png' : 'assets/dashboard-icons/without-fill/statements.png'} alt="" />
                                </div>
                                <div className="col-10">
                                    <Link to="/dashboard/kyc">KYC</Link>
                                </div>
                            </div>
                        </li>
                    }
                    <li style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (window.confirm('Are you sure want to logout?')) {

                                localStorage.clear();
                                navigate('/');

                            }
                        }
                        }
                        className={activatedPath == 'statements' ? "selected-menuitem" : ''}>
                        <div className="row">
                            <div className="col-2">
                                <img className="menu-icon" src='assets/dashboard-icons/without-fill/logout.png' alt="" />
                            </div>
                            <div className="col-10">
                                Logout
                            </div>
                        </div>
                    </li>
                </ul> */}
            </div>
        </>
    );
};

export default DashboardNav;
