import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import Billpayment from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    const { type } = useParams();
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const billerid = sessionStorage.getItem("Biller");
    const billerCat = localStorage.getItem("billerName");
    const [successdetails, setsuccessdetails] = useState<any>();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const usernumber = sessionStorage.getItem("usernumber");
    const success = sessionStorage.getItem("success");
    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (success) {
            setsuccessdetails(JSON.parse(success));
            console.log(JSON.parse(success));
        }
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>

                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper">
                        <div className="payment-header">
                            <div className="header-wrapper">
                                <h2>Pay Bills</h2>
                            </div>
                            <img
                                className="payment-header-icon"
                                src="assets/billpayment/billpayment_header.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="payment-wrapper">
                        <Billpayment active="QuickPay" />
                    </div>
                    <div className="successWrappe">
                        <div className="successWrapper">
                            <img src="assets/img/assured.svg" alt="" />
                            <p>Your Transaction is Successfull..!</p>
                            <div className="success-details">
                                <div className="success-detail">
                                    <p>Transaction ID</p>
                                    <p>:</p>
                                    <p>{successdetails?.txnRefId}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Biller Id</p>
                                    <p>:</p>
                                    <p>{billerid}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Biller Name</p>
                                    <p>:</p>
                                    <p>{billerCat}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Customer Name</p>
                                    <p>:</p>
                                    <p>{successdetails?.RespCustomerName}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Mobile Number</p>
                                    <p>:</p>
                                    <p>{usernumber}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Bill amount</p>
                                    <p>:</p>
                                    <p>
                                        ₹{" "}
                                        {Number(successdetails?.RespAmount) /
                                            100}
                                    </p>
                                </div>
                                <div className="success-detail">
                                    <p>Customer Convenience fee</p>
                                    <p>:</p>
                                    <p>₹ {successdetails?.CustConvFee}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Total Amount</p>
                                    <p>:</p>
                                    <p>
                                        ₹{" "}
                                        {Number(successdetails?.RespAmount) /
                                            100 +
                                            Number(
                                                successdetails?.CustConvFee
                                            )}{" "}
                                    </p>
                                </div>
                                <div className="success-detail">
                                    <p>Transaction Date & time</p>
                                    <p>:</p>
                                    <p>{successdetails?.TransactionDateTime}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Initiating Channel </p>
                                    <p>:</p>
                                    <p>AGT</p>
                                </div>
                                <div className="success-detail">
                                    <p>Payment Mode</p>
                                    <p>:</p>
                                    <p>Cash</p>
                                </div>
                                <div className="success-detail">
                                    <p>Transaction Status</p>
                                    <p>:</p>
                                    <p>{successdetails?.responseReason}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Approval Number</p>
                                    <p>:</p>
                                    <p>{successdetails?.approvalRefNumber}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
