import React, { useState, useEffect } from "react";
import TopnavClasses from "./TopNav.module.css";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
// import video from "";
import MObile from "../../../../images/Bhart.png";
import { FaUser } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import Signup from "../../../Signup";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../images/HomePage_Updated/logo.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { IoIosAppstore } from "react-icons/io";

import { useLocation } from "react-router-dom";
import Login from "../../../Login";

function TopNav() {
    const [show, setShow] = useState(false);
    const [modalShowLogin, setModalShowLogin] = React.useState(false);
    const Navigate = useNavigate();

    const [modalShow, setModalShow] = React.useState(false);
    const [stickyNav, setStickyNav] = useState<boolean>(false);

    const Links = [
        { label: "Home", LinkTo: "#home" },
        { label: "About", LinkTo: "#about" },
        { label: "Benefits", LinkTo: "#benefits" },
        { label: "Contact", LinkTo: "#contact" },
    ];
    const scrollToHash = (hash: any) => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                const yOffset = -50; // Offset value to adjust the scroll position
                const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    };
    const location = useLocation();

    useEffect(() => {
        scrollToHash(location.hash);
    }, [location]);
    function toggleStickyNav(
        setStickyNav: React.Dispatch<React.SetStateAction<boolean>>
    ): void {
        if (window.scrollY >= 500) {
            setStickyNav(true);
        } else {
            setStickyNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", () => toggleStickyNav(setStickyNav));
        return () => {
            window.removeEventListener("scroll", () =>
                toggleStickyNav(setStickyNav)
            );
        };
        // eslint-disable-next-line
    }, []);
    return (
        <div className={`${TopnavClasses["Container"]}`} id="home">
            {modalShow && (
                <Signup
                    onLoginRedirect={() => {
                        setModalShow(false);
                    }}
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false);
                    }}
                    // onExiting={reload}
                />
            )}
            {modalShowLogin && (
                <Login
                    onSignupRedirect={() => {
                        console.log("re signup");
                        setModalShowLogin(false);
                    }}
                    setsignup={setModalShow}
                    show={modalShowLogin}
                    onHide={() => setModalShowLogin(false)}
                    // onExiting={reload}
                />
            )}
            {/*  <video autoPlay loop muted>
                <source src="/assets/bgvideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            {stickyNav && <div style={{ padding: "2.5rem" }}></div>}
            <div
                className={`${TopnavClasses["Topnav"]} ${
                    stickyNav ? TopnavClasses["StickyNavbar"] : ""
                }`}>
                <div className={TopnavClasses["img"]}>
                    <img src={Logo} alt="Logo" />
                </div>
                <div className={TopnavClasses["Navbar"]}>
                    <div
                        className={`${TopnavClasses["Links"]} ${
                            show ? TopnavClasses["Links_show"] : ""
                        }`}>
                        {Links.map((item) => (
                            <NavLink to={item.LinkTo}>{item.label}</NavLink>
                        ))}
                        {sessionStorage.getItem("token") ? (
                            <button
                                className={TopnavClasses["Signup"]}
                                onClick={() => Navigate("/dashboard")}>
                                Dashboard
                            </button>
                        ) : (
                            <button
                                onClick={() => setModalShowLogin(true)}
                                className={TopnavClasses["Signup"]}>
                                Login{" "}
                                <span className={TopnavClasses["icon"]}>
                                    <FaUser />
                                </span>
                            </button>
                        )}
                    </div>
                    <button
                        onClick={() => setShow((prev) => !prev)}
                        className={TopnavClasses["hamburger-btn"]}>
                        <GiHamburgerMenu />
                    </button>
                </div>
            </div>
            <div className={TopnavClasses["Navbar-Content"]}>
                <div className={TopnavClasses["Navbar-Content_left"]}>
                    <h3>One App for All Utility Bill payments</h3>
                    <p>
                        Your one-stop shop for online bill payments! Make them
                        easy and hassle free by making bill payments instantly
                    </p>
                    {/*     <div className={TopnavClasses["Action-controls"]}>
                        <button>
                            <IoLogoGooglePlaystore size={25} /> Playstore
                        </button>
                        <button>
                            <IoIosAppstore size={25} /> Appstore
                        </button>
                    </div> */}
                </div>
                <div className={TopnavClasses["Navbar-Content_right"]}>
                    <img src={MObile} alt="" />
                </div>
            </div>
        </div>
    );
}

export default TopNav;
