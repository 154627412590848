import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";
import Razorpay from "./RazorBillPay";
import Confirm from "./ConfirmModal/Confirm";
import Spinner from "../spinner";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    const [PayableAmount, setPayableAmount] = useState(0);
    const [minData, setMinData] = useState({ min: 0 });
    const [commissionPercentage, setCommissionPercentage] = useState(1.5);
    const [modal, setModal] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const { type } = useParams();
    // const [loading,setLoading]=useState([])
    const [billdetails, setbilldetails] = useState<any>();
    const [billername, setbillername] = useState<any>(
        sessionStorage.getItem("billerName")
    );
    // const billername = ;
    const [userIpAddress, setUserIpAddress] = useState("");
    let bill = sessionStorage.getItem("billdetails");
    console.log(bill);
    const [amount, setamount] = useState<any>();
    let [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");

    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");
    useEffect(() => {
        let userDetails: any = localStorage.getItem("userdetails");
        if (userDetails) {
            userDetails = JSON.parse(userDetails);
            setCommissionPercentage(userDetails?.BBPSChargesPercentage);
            setPayableAmount(
                Math.ceil(
                    (Number(sessionStorage.getItem("amount")) / 100) *
                        (userDetails?.BBPSChargesPercentage / 100) +
                        Number(sessionStorage.getItem("amount")) / 100
                )
            );
        } else {
            setPayableAmount(
                Math.ceil(
                    (Number(sessionStorage.getItem("amount")) / 100) *
                        (1.5 / 100) +
                        Number(sessionStorage.getItem("amount")) / 100
                )
            );
        }
        setamount(Number(sessionStorage.getItem("amount")) / 100);
        if (bill) {
            setbilldetails(
                JSON.parse(sessionStorage.getItem("billdetails") || "")
            );
            setLoading(false);
            console.log(
                JSON.parse(sessionStorage.getItem("billdetails") || "")
            );
        }
    }, []);
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const amountChange = (e: any) => {
        setamount(e.target.value);
        setPayableAmount(
            Number(e.target.value) * (commissionPercentage / 100) +
                Number(e.target.value)
        );
    };
    useEffect(() => {
        // if (bill) {
        //     setbilldetails(JSON.parse(billdetails));
        //     console.log(billdetails);
        // }

        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes", bill]);
    // showSection == 'biller-list'
    const convertInfoValueToNumber = (data: any) => {
        return data.map((item: any) => {
            const { infoValue } = item;
            const parsedValue = parseFloat(infoValue);
            return {
                ...item,
                infoValue: isNaN(parsedValue) ? infoValue : parsedValue,
            };
        });
    };
    const payHandler = () => {
        setSpinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        const billerAdhoc = localStorage.getItem("billerAdhoc");
        let id = {};
        if (billerAdhoc) {
            id = {
                RequestID: billdetails?.RequestID,
            };
        }
        let additionalInfo: any = [];
        if (billdetails?.BillDetails?.additionalInfo) {
            additionalInfo = [
                ...ensureArray(billdetails?.BillDetails?.additionalInfo?.info),
            ];
        }

        let data: any = {
            billerId: sessionStorage.getItem("billerid"),
            Amount: Number(amount) * 100,
            billerAdhoc:
                localStorage.getItem("billerAdhoc") == "false" ? false : true,
            ...id,
            mobileNumber: localStorage.getItem("mobilenumber"),
            Params: {
                additionalInfo: {
                    info: additionalInfo.length > 0 ? additionalInfo : 0,
                },
                inputParams: {
                    input: [...billdetails?.BillDetails?.inputParams.input],
                },
                billerResponse: { ...billdetails?.BillDetails?.billerResponse },

                paymentMethod: {
                    paymentMode: "NEFT",
                    quickPay: "N",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "IFSC",
                            infoValue: "TESTCCAV0001",
                        },
                        {
                            infoName: "AccountNo",
                            infoValue: "PG",
                        },
                    ],
                },
            },
        };
        /* let data: any = {
            customerInfo: {
                customerMobile: sessionStorage.getItem("mobilenumber"),
                customerEmail: "",
                customerAdhaar: "",
                customerPan: "",
            },
            billerId: sessionStorage.getItem("billerid"),
            Amount: Number(amount) * 100,
            billerAdhoc: localStorage.getItem("billerAdhoc"),
            ...id,
            // mobileNumber: ,

            inputParams: {
                input: [...billdetails?.BillDetails?.inputParams.input],
            },
            billerResponse: { ...billdetails?.BillDetails?.billerResponse },

            paymentMethod: {
                paymentMode: "Wallet",
                quickPay: "N",
                splitPay: "N",
            },
            amountInfo: {
                amount: Number(amount) * 100,
                currency: "356",
                custConvFee: "0",
            },
            paymentInfo: [
                {
                    info: [
                        {
                            infoName: "WalletName",
                            infoValue: "Paygen",
                        },
                        {
                            infoName: "MobileNo",
                            infoValue: localStorage.getItem("mobilenumber"),
                        },
                    ],
                },
            ],
        }; */
        if (additionalInfo.length > 0) {
            data = {
                ...data,
                additionalInfo: {
                    info: additionalInfo,
                },
            };
        }

        // if (userId == "1104299") {
        //     let body = {
        //         amount: Number(amount),
        //         paymentgateway: "PineLabs",
        //         ipAddress: userIpAddress,
        //     };
        //     let config = {
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem("token")}`,
        //         },
        //     };
        //     console.log(body);
        //     // return;
        //     LoginServices.pineLabOrders(body, config)
        //         .then((response: any) => {
        //             setSpinner(false);
        //             if (response.data.Status == 1) {
        //                 window.open(response.data.RedirectURL, "_blank");
        //             } else if (response.data.Status == 0) {
        //                 alert(response.data.Message);
        //                 console.log("Message");
        //             }
        //         })
        //         .catch(function (error) {
        //             setSpinner(false);
        //             // console.log(error.response.data.Message);
        //             alert(error.response.data.Message); // this is the part you need that catches 400 request
        //         });
        //     return;
        // }
        LoginServices.paybill(data, config)
            .then((response: any) => {
                setSpinner(false);
                if (response.data?.Status == 1) {
                    setModal(false);
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.data?.BillPayResponse)
                    );
                    navigate(
                        `/dashboard/bill-payments/paybill-success/${type}`
                    );
                } else if (response?.data.Status == 0) {
                    alert(response?.data?.Message);
                }
                console.log(response);
            })
            .catch((err: any) => {
                setSpinner(false);
                // console.log();
                // alert(response?.data?.Message);
                alert(
                    err.response.data.Message ||
                        "some thing went wrong try after sometime"
                );
            });
    };
    const Payhandle = () => {
        let data = ensureArray(
            billdetails?.BillDetails?.additionalInfo?.info
        ).filter((item: any) => item.infoName == "Minimum Amount for Top-up");
        /*  if (data.length > 0 && data[0]?.infoValue > Number(amount)) {
            return alert("Min Payable Amount is " + data[0].infoValue);
        } */
        setModal(true);
    };
    function ensureArray(response: any) {
        // Wrap the response into an array if it is not already an array
        if (response) {
            return Array.isArray(response) ? response : [response];
        }
        return [];
    }
    console.log(billdetails);
    return (
        <>
            {spinner && <Spinner />}
            {modal && (
                <Confirm
                    submitenabler={spinner}
                    commissionPercentage={commissionPercentage}
                    setSpinner={setSpinner}
                    payHandler={payHandler}
                    passBlc={amount}
                    commissionamount={PayableAmount}
                    show={modal}
                    onHide={() => {
                        setModal(false);
                    }}
                />
            )}
            {loading ? (
                <Spinner />
            ) : (
                <div className="main-bg">
                    <div>
                        <DashboardNav active={"bill-payments"}></DashboardNav>
                    </div>
                    <div style={{ backgroundColor: "#f6f7ff" }}>
                        <div className="paymentWrapper">
                            <div className="payment-header">
                                <div className="header-wrapper">
                                    <h2>Pay Bills</h2>
                                    <img
                                        style={{ alignSelf: "center" }}
                                        src="/assets/img/arrow.svg"
                                        alt=""
                                    />
                                    <h3>{type}</h3>
                                </div>
                                <img
                                    className="payment-header-icon"
                                    src="assets/billpayment/billpayment_header.png"
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="amount-wrapper">
                            <div className="amount-data">
                                <label htmlFor="billername">Biller Name</label>
                                <input
                                    type="text"
                                    placeholder={billername}
                                    disabled
                                />
                            </div>
                            {ensureArray(
                                billdetails?.BillDetails?.additionalInfo?.info
                            )?.map((item: any) => {
                                return (
                                    <div className="amount-data">
                                        <label htmlFor={item?.infoName}>
                                            {item?.infoName}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={item?.infoValue}
                                            disabled
                                        />
                                    </div>
                                );
                            })}
                            {ensureArray(
                                billdetails?.BillDetails?.inputParams?.input
                            )?.map((item: any) => {
                                return (
                                    <div className="amount-data">
                                        <label htmlFor={item?.input?.paramName}>
                                            {item?.paramName}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={item?.paramValue}
                                            disabled
                                        />
                                    </div>
                                );
                            })}
                            {Object.entries(
                                billdetails?.BillDetails?.billerResponse
                            ).map((item: any) => (
                                <div className="amount-data">
                                    <label htmlFor="due date">{item[0]}</label>
                                    <input
                                        type="text"
                                        value={item[1] || ""}
                                        disabled
                                    />
                                </div>
                            ))}
                            {/*  <div className="amount-data">
                            <label htmlFor="due date">Due Date</label>
                            <input
                                type="text"
                                placeholder={
                                    
                                        ?.dueDate
                                }
                                disabled
                            />
                        </div> */}
                            <div className="amount-data">
                                <label htmlFor="billAmount">bill Amount</label>
                                <input
                                    type="number"
                                    value={amount}
                                    onChange={amountChange}
                                />
                            </div>

                            <button onClick={payHandler}>Pay</button>
                            {/* <button onClick={Payhandle}>Pay</button> */}
                        </div>
                    </div>
                    <div>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default KycPending;
