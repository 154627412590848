import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillpaymentComplaintSubmenu from "./BillpaymentComplaintSubmenu";
import Spinner from "../spinner";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const [spinner, setspinner] = useState<any>(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const onSubmit = (data: any) => {
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        console.log(data);
        LoginServices.TrackComplaint(data, config)
            .then((res) => {
                setspinner(false);
                if (res?.data?.Status == 1) {
                    sessionStorage.setItem(
                        "complaintStatus",
                        JSON.stringify(res?.data?.TrackComplaintResponse)
                    );
                    navigate(
                        "/dashboard/bill-payments/billPaymentComplaintStatusFetch"
                    );
                } else if (res?.data?.Status == 0) {
                    setspinner(false);
                    alert(res?.data?.Message);
                }
                console.log(res);
                ///
            })
            .catch((err) => {
                setspinner(false);
                alert("some thing went wrong try again");
            });
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Complaint</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="Complaint-wrapper">
                    <div className="Complaint-Nav">
                        <BillpaymentNav active="complaints" />
                    </div>
                    <div className="Complaint-submenuWrapper">
                        <BillpaymentComplaintSubmenu active={"status"} />
                        <div className="Complaint-formwrapper">
                            <h1>Check Complaint Status</h1>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="Complaint-form-control">
                                    <label htmlFor="Complaint ID">
                                        Complaint ID
                                    </label>
                                    <div>
                                        <input
                                            className={`input ${
                                                errors?.complaintId &&
                                                "inputerror"
                                            }`}
                                            type="text"
                                            {...register("complaintId", {
                                                required:
                                                    "complaintId is required",
                                            })}
                                        />
                                        {errors?.complaintId && (
                                            <p className="error">
                                                {errors?.complaintId.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div className="Complaint-form-control">
                                    <label htmlFor="Select Reason">
                                        Type of Complaint
                                    </label>
                                    <div>
                                        <select
                                            defaultValue=""
                                            className={`select ${
                                                errors?.complaintType &&
                                                "inputerror"
                                            }`}
                                            {...register("complaintType", {
                                                required:
                                                    "complaintType is required",
                                            })}>
                                            <option value="" disabled>
                                                TYPE
                                            </option>
                                            <option value="SERVICE">
                                                SERVICE
                                            </option>
                                            <option value="TRANSACTION">
                                                TRANSACTION
                                            </option>
                                        </select>
                                        {errors?.complaintType && (
                                            <p className="error">
                                                {errors?.complaintType.message}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <div
                                    className="button"
                                    style={{ paddingTop: "10rem" }}>
                                    <button type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
