import React, { useState, ChangeEvent } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import "./Signup.css";
import { isCompositeComponent } from "react-dom/test-utils";
import SignupServices from "../services/SignupServices";
import { useNavigate } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { FaUser } from "react-icons/fa";

import Signupclass from "./Sign.module.css";
const Signup: React.FC<any> = (props: any) => {
    const [signupData, setSignup] = useState<any>({});
    const [signupVerifyOTP, setVerifyOTP] = useState<any>({});
    const [signupUser, setSignupUser] = useState<any>({});
    const [errorMsg, setErrorMsg] = useState<String>("");
    const [successMsg, setSuccessMsg] = useState<String>("");

    const [refCode, setRefCode] = useState<any>("");

    const [token, setToken] = useState<any>("");
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setSignup({ ...signupData, [name]: value });
    };

    const handleOTPChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setVerifyOTP({ ...signupVerifyOTP, [name]: value });
    };

    const handleSignUpFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        console.log(name, value);

        setSignupUser({ ...signupUser, [name]: value });
    };
    const [getOTP, setgetOTP] = useState(false);
    const [verifiedOTP, setverifiedOTP] = useState(false);
    const [otp, setOTP] = useState(0);

    const navigate = useNavigate();
    const doSignup = () => {
        // console.log(signupUser);

        const paLo: any = signupUser;
        paLo.emailId = signupData.emailId;

        if (localStorage.getItem("rCode")?.length) {
            paLo.referredByCode = refCode;
        }
        setErrorMsg("");
        console.log(paLo);
        SignupServices.RegisterUser(paLo)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    setToken(response.data.Token);
                    localStorage.setItem(
                        "userid",
                        response?.data?.userDetails?.userId
                    );
                    sessionStorage.setItem("token", response?.data?.Token);
                    localStorage.setItem(
                        "firstName",
                        response?.data?.userDetails?.firstName
                    );
                    // localStorage.setItem(
                    //     "payoutChargeAmount",
                    //     response?.data?.userDetails?.payoutChargeAmount
                    // );
                    localStorage.setItem(
                        "city",
                        response?.data?.userDetails?.city
                    );
                    alert(response.data.Message);
                    navigate("/dashboard");
                } else {
                    alert(response.data.Message);
                    setErrorMsg(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e);
                setErrorMsg(e?.response?.data?.Message);
            });
    };

    const verifyOTP = () => {
        console.log(signupUser);
        setErrorMsg("");
        SignupServices.verifyOTP(signupVerifyOTP)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    //setOTP(response.data.OTP);
                    setverifiedOTP(true);
                    setSignupUser({
                        ...signupUser,
                        mobileNumber: signupVerifyOTP.mobileNumber,
                    });
                } else {
                    setverifiedOTP(false);
                    //alert("Something went wrong. Please try again.");
                    //alert(response.data.Message);
                    setErrorMsg(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((error: any) => {
                console.log(error);
                setErrorMsg(error?.response?.data?.Message);
                setverifiedOTP(false);
            });
    };
    const resendOTP = () => {
        // alert('j')
        createOTP();
    };

    //on submit signup
    const createOTP = () => {
        var data = {
            mobileNumber: signupData.mobileNumber,
            emailId: signupData.emailId,
            referredbyUserPhoneNumber: signupData.referredbyUserPhoneNumber,
        };
        const paL: any = data;

        if (refCode.length) {
            paL.referredByCode = refCode;
            localStorage.setItem("rCode", refCode);
        } else {
            localStorage.removeItem("rCode");
        }

        console.log(paL);
        setErrorMsg("");
        SignupServices.create(paL)
            .then((response: any) => {
                if (response.data.Status == 1) {
                    setOTP(response.data.OTP);
                    setgetOTP(true);
                    setVerifyOTP({
                        ...signupVerifyOTP,
                        mobileNumber: data.mobileNumber,
                        OTP: response.data.OTP,
                    });
                } else {
                    setgetOTP(false);
                    setErrorMsg(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((error: any) => {
                console.log(error.response);
                setErrorMsg(error?.response?.data?.Message);
            });
    };

    return (
        <Modal
            {...props}
            size="xl"
            backdrop={false}
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="login-modal1"
            centered>
            <Modal.Body>
                <div className={Signupclass["border"]}>
                    <h3>Signup</h3>
                    <button
                        className={Signupclass["close"]}
                        onClick={() => {
                            props.onHide();
                        }}>
                        x
                    </button>
                </div>
                <div className={Signupclass["wrapper"]}>
                    <div className={Signupclass["Container"]}>
                        <p style={{ textAlign: "left" }}>
                            Enter Your Details to get started
                        </p>
                        {verifiedOTP == false && (
                            <>
                                <div className={Signupclass["input-wrapper"]}>
                                    <input
                                        type="text"
                                        name="mobileNumber"
                                        placeholder="Enter Mobile Number"
                                        value={signupData.mobileNumber}
                                        onChange={handleInputChange}
                                    />
                                    <FaPhoneAlt
                                        className={Signupclass["icon"]}
                                    />
                                </div>
                                <div className={Signupclass["input-wrapper"]}>
                                    <input
                                        type="email"
                                        name="emailId"
                                        placeholder="Enter Email"
                                        value={signupData.emailId}
                                        onChange={handleInputChange}
                                    />
                                    <MdEmail className={Signupclass["icon"]} />
                                </div>
                                <div className={Signupclass["input-wrapper"]}>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="referredbyUserPhoneNumber"
                                        placeholder="Enter Referral Mobile Number"
                                        value={
                                            signupData.referredbyUserPhoneNumber
                                        }
                                        onChange={handleInputChange}
                                    />
                                    <FaPhoneAlt
                                        className={Signupclass["icon"]}
                                    />
                                </div>
                                {getOTP == true && (
                                    <div
                                        className={
                                            Signupclass["input-wrapper"]
                                        }>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter OTP"
                                            name="OTP"
                                            onChange={handleOTPChange}
                                        />
                                        <FaLock
                                            className={Signupclass["icon"]}
                                        />
                                    </div>
                                )}
                                {errorMsg && (
                                    <span className="text-danger">
                                        {errorMsg}
                                    </span>
                                )}
                                {getOTP == true && (
                                    <div className={Signupclass["action-btns"]}>
                                        <button onClick={verifyOTP}>
                                            Verify
                                        </button>
                                        <button onClick={resendOTP}>
                                            Resend OTP{" "}
                                        </button>
                                    </div>
                                )}

                                {getOTP == false && (
                                    <>
                                        <button
                                            className="getOTP"
                                            onClick={createOTP}>
                                            Get OTP
                                        </button>
                                    </>
                                )}
                            </>
                        )}

                        {verifiedOTP == true && (
                            <>
                                <div className={Signupclass["input-wrapper"]}>
                                    <FaUser className={Signupclass["icon"]} />
                                    <input
                                        type="text"
                                        placeholder="Enter Your Name"
                                        name="firstName"
                                        onChange={handleSignUpFieldChange}
                                    />
                                </div>

                                <div className={Signupclass["terms"]}>
                                    <span>
                                        <input
                                            type="checkbox"
                                            style={{
                                                border: "1px solid #6865F0",
                                            }}
                                        />
                                    </span>
                                    &nbsp;&nbsp;
                                    <span>
                                        I Agree to Paygen pro Terms & Conditions
                                    </span>
                                </div>

                                <button className="getOTP" onClick={doSignup}>
                                    Sign Up
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Signup;
