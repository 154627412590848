import axios from "axios";
let isLoggingOut = false;
const apiClient = axios.create({
    baseURL: "https://api.paygenpro.in/api",
    // baseURL: "http://3.109.197.3:3000/api",
    // baseURL: "http://1796-183-82-104-227.ngrok",
    headers: {
        "Content-type": "application/json",
    },
});

apiClient.interceptors.response.use(
    (response) => {
        console.log("Response received:", response);
        return response; // Return the full response object if you need headers or status codes
    },
    (error) => {
        console.error("Error intercepted:", error);

        if (!error.response) {
            // Network error
            alert("Network error. Please check your connection.");
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !isLoggingOut) {
            isLoggingOut = true; // Set the flag to prevent multiple logouts
            alert("Session expired or Invalid Token. Please log in again.");
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = "/";
        }

        return Promise.reject(error); // Ensure errors can still be handled downstream
    }
);

export default apiClient;
