import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import Razor from "./Razor";
import Payu from "./payu";
import Disabled from "./disabledgateway";

// @ts-ignore
import { load } from "@cashfreepayments/cashfree-js";

function Deposits() {
    const navigate = useNavigate();
    const formEl = useRef<HTMLFormElement>(null);
    const transid = useRef<any>(null);
    const hash = useRef<any>(null);
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [PaymentGateWay, setGateway] = useState<any>(2);
    const [addBlc, setAddBlc] = useState<any>();
    const userId = localStorage.getItem("userid");
    const [paymentType, setPaymentType] = useState<any>("razorpay");
    const [submitenabler, setsubmitenabler] = useState<any>(true);
    const [userdetails, setuserdetails] = useState<any>();
    const [commision, setcommision] = useState<any>();
    const [ticked, setTicked] = useState<any>(true);
    const [userIpAddress, setUserIpAddress] = useState("");
    const [PaymentStatus, setpaymentStatus] = useState<any>({});
    const [pg1commission, setpg1commission] = useState<any>();
    const [pg2commission, setpg2commission] = useState<any>();
    const [userDetails, setUserDetails] = useState<any>();

    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
        setcommision(localStorage.getItem("commission"));
        setuserdetails(JSON.parse(localStorage.getItem("userdetails") || ""));
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getGatewayStatus(config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setpaymentStatus({
                            isRazorPayPGAvailable:
                                response.data.isRazorPayPGAvailable,
                            isRazorPayPG2Available:
                                response.data.isRazorPayPG2Available,
                            isCashfreeEduAvailable:
                                response.data.isCashfreeEduAvailable,

                            isCashfreeAvailable:
                                response.data.isCashfreeAvailable,
                        });
                        let gate =
                            response.data.isRazorPayPGAvailable == 1
                                ? 1
                                : response.data.isCashfreeAvailable == 1
                                ? 2
                                : 3;
                        setGateway(gate);
                        if (response.data.isRazorPayPGAvailable == 1) {
                            setPaymentType("razorpay");
                        } else if (response.data.isRazorPayPG2Available == 1) {
                            setPaymentType("razorpay2");
                        }
                    } else {
                        alert("something went wrong pleasetry again");
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.userPercentage(userId, config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setpg1commission(
                            response.data.WalletCommissionPercentage
                        );

                        setpg2commission(
                            response.data.PG2WalletCommissionPercentage
                        );
                    } else {
                        alert("something went wrong please try again");
                    }
                })
                .catch((e: Error) => {
                    console.log(e);
                });

            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
            LoginServices.getUserDetailsByID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setUserDetails(response?.data?.UserDetails);
                        localStorage.setItem(
                            "isKycDone",
                            response?.data?.UserDetails?.isKYCverified
                        );
                    } else {
                    }
                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [""]);
    const loginFormSubmit = () => {
        const payload = {
            amount: addBlc,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        LoginServices.createRazorpayOrder(payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                } else if (response.data.Status == 0) {
                    alert(response.data.Message);
                    console.log("Message");
                }

                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };
    const submithand = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        let payload = JSON.stringify({
            amount: addBlc,
            paymentGatewayType: "payu",
            ipAddress: userIpAddress,
        });
        LoginServices.payu(payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    // transid.current.value = response.data.orderId;
                    window.location.replace(response.data.SuccessPaymentURL);
                } else {
                    alert("something Went wrong please try again");
                }

                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });

        console.log(transid?.current?.value);
        console.log(transid?.current?.value);
        // hash.current.value = 34434;

        // formEl.current && formEl.current.submit();
    };
    console.log(submitenabler);
    const changehandler = (e: any) => {
        setTicked(e.target.checked);
        if (
            e.target.checked &&
            addBlc &&
            !isNaN(addBlc) &&
            paymentType !== ""
        ) {
            console.log(e.target.checked);
            setsubmitenabler(false);
        } else {
            console.log(e.target.checked);
            setsubmitenabler(true);
        }
    };
    const paymentChangeHandler = (e: any) => {
        setPaymentType(e.currentTarget.value);
    };
    const disablehandler = () => {};

    const doPayment = async () => {
        if (addBlc > 500000) {
            alert("Deposits more than 5,00,000 not allowed");
            return;
        }

        let payload = {
            amount: parseInt(addBlc),
            paymentgateway: "Cashfree",
            ipAddress: userIpAddress,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        let cashfree: any;
        var initializeSDK = async function () {
            cashfree = await load({
                mode: "production",
            });
        };
        initializeSDK();
        if (PaymentGateWay == 3) {
            LoginServices.createCashfreepayOrderEdu(payload, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        let checkoutOptions = {
                            paymentSessionId: response?.data?.SessionId,
                            redirectTarget: "_modal",
                        };
                        cashfree.checkout(checkoutOptions);
                    } else if (response.data.Status == 0) {
                        alert(response.data.Message);
                        console.log("Message");
                    }
                })
                .catch(function (error) {
                    alert(error.response.data.Message); // this is the part you need that catches 400 request
                });
        } else {
            LoginServices.createCashfreepayOrder(payload, config)
                .then((response: any) => {
                    if (response.data.Status == 1) {
                        let checkoutOptions = {
                            paymentSessionId: response?.data?.SessionId,
                            redirectTarget: "_modal",
                        };
                        cashfree.checkout(checkoutOptions);
                    } else if (response.data.Status == 0) {
                        alert(response.data.Message);
                        console.log("Message");
                    }
                })
                .catch(function (error) {
                    alert(error.response.data.Message); // this is the part you need that catches 400 request
                });
        }
    };

    return (
        <>
            {/*     {PaymentStatus?.isRazorPayPGAvailable == 0 &&
                PaymentStatus?.isRazorPayPG2Available == 0 && (
                    <Disabled news={userDetails?.news} />
                )} */}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"deposits"}></DashboardNav>
                </div>
                <div>
                    <p className="deposit-head">Load Wallet</p>
                    <div className="total-wallet-box-main">
                        <div>
                            <div style={{ width: "100%" }}>
                                <input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    onChange={(e: any) => {
                                        // if (!isNaN(e.target.value)){
                                        setAddBlc(e.target.value);
                                        if (e.target.value.length == 0) {
                                            setsubmitenabler(true);
                                            console.log(1);
                                        } else if (
                                            ticked &&
                                            Number(e.target.value)
                                        ) {
                                            console.log(2);
                                            setsubmitenabler(false);
                                        }
                                        // }else{
                                        //     alert('Only numbers allowed')
                                        // }
                                    }}
                                    autoComplete="off"
                                    className="add-blc-ip"
                                    placeholder="Enter Top up Amount"
                                />
                            </div>
                            {/* <p className="payment-methods-heading">
                                Payment Methods
                            </p> */}
                            <div className="payment-btns">
                                {/*    {PaymentStatus?.isRazorPayPGAvailable == 1 && (
                                    <div>
                                        <input
                                            type="radio"
                                            id="Razor Pay"
                                            name="Payment"
                                            value="razorpay"
                                            defaultChecked={
                                                PaymentStatus?.isRazorPayPGAvailable !=
                                                0
                                            }
                                            disabled={
                                                PaymentStatus?.isRazorPayPGAvailable ===
                                                0
                                            }
                                            onChange={paymentChangeHandler}
                                        />
                                      
                                        <p>
                                            Normal /Business /Corporate(
                                            {pg1commission}%)
                                        </p>
                                    </div>
                                )} */}
                                {/* {PaymentStatus?.isRazorPayPG2Available == 1 && (
                                    <div>
                                        <input
                                            type="radio"
                                            id="Razor Pay"
                                            name="Payment"
                                            value="razorpay2"
                                            defaultChecked={
                                                PaymentStatus?.isRazorPayPGAvailable ==
                                                0
                                            }
                                            disabled={
                                                PaymentStatus?.isRazorPayPG2Available ===
                                                0
                                            }
                                            onChange={paymentChangeHandler}
                                        />
                                     
                                        <p>
                                            Slice /Uni /Prepaid Cards(
                                            {pg2commission}%)
                                        </p>
                                    </div>
                                )} */}

                                {/* {PaymentStatus?.isPayUPGAvailable == 1 && (
                                    <div>
                                        <input
                                            type="radio"
                                            id="payu"
                                            name="Payment"
                                            value="payu"
                                            defaultChecked={
                                                PaymentStatus?.isPayUPGAvailable !=
                                                    0 &&
                                                PaymentStatus?.isRazorPayPGAvailable !=
                                                    1
                                            }
                                            disabled={
                                                PaymentStatus?.isPayUPGAvailable ===
                                                0
                                            }
                                            onChange={paymentChangeHandler}
                                        />
                                        <label htmlFor="payu">
                                            <img
                                                src="assets/payu.png"
                                                width={"80px"}
                                                alt="payu"
                                            />
                                        </label>
                                    </div>
                                )} */}
                                {/* <button
                                    className={
                                        paymentType === "Razor"
                                            ? "payactive"
                                            : ""
                                    }
                                    onClick={() => setPaymentType("Razor")}>
                                    Razor Pay
                                </button>
                                <button
                                    className={
                                        paymentType === "payu"
                                            ? "payactive"
                                            : ""
                                    }
                                    onClick={() => {
                                        setPaymentType("payu");
                                    }}>
                                    Payu
                                </button> */}
                            </div>
                            {/*   <div
                                className="d-flex align-items-center "
                                style={{ gap: "0.6rem", margin: "0.5rem 0" }}>
                                <input
                                    type={"checkbox"}
                                    onChange={changehandler}
                                    value={ticked}
                                    style={{
                                        position: "unset",
                                        opacity: 1,
                                        pointerEvents: "unset",
                                    }}
                                />
                                <p
                                    style={{
                                        color: "red",
                                        textAlign: "center",
                                        margin: 0,
                                    }}>
                                    {paymentType == "razorpay2"
                                        ? pg2commission
                                        : commision}
                                    % will be deducted as a processing Fee.
                                </p>
                            </div> */}
                            <div className={"drop-down"}>
                                <select
                                    name=""
                                    id=""
                                    value={PaymentGateWay}
                                    onChange={(e) => setGateway(e.target.value)}
                                    style={{ display: "block" }}>
                                    {PaymentStatus?.isCashfreeAvailable ==
                                        1 && (
                                        <option
                                            value={2}
                                            disabled={
                                                PaymentStatus?.isCashfreeAvailable ==
                                                0
                                            }>
                                            Cashfree Utility{" "}
                                        </option>
                                    )}
                                    {PaymentStatus?.isCashfreeEduAvailable ==
                                        1 && (
                                        <option
                                            value={3}
                                            disabled={
                                                PaymentStatus?.isCashfreeEduAvailable ==
                                                0
                                            }>
                                            {" "}
                                            {userId == "1111111"
                                                ? " cashfree pg2"
                                                : "Cashfree Edu"}{" "}
                                        </option>
                                    )}
                                    {PaymentStatus?.isRazorPayPGAvailable ==
                                        1 && (
                                        <option
                                            value={1}
                                            disabled={
                                                PaymentStatus?.isRazorPayPGAvailable ==
                                                0
                                            }>
                                            Razor Pay{" "}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "1rem",
                                    gap: "1rem",
                                }}>
                                <p style={{ color: "red", fontWeight: "600" }}>
                                    Note:
                                </p>
                                <ul>
                                    <li>
                                        {" "}
                                        <b style={{ fontWeight: "600" }}>
                                            Razorpay Utility Charges:
                                        </b>
                                        <ul>
                                            <li>Visa/Rupay 1.3%</li>
                                            <li>Mastercards 2.55%</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b style={{ fontWeight: "600" }}>
                                            Cashfree Utility Charges(Including
                                            Corporate Cards):
                                        </b>
                                        <ul>
                                            <li>Visa/Rupay 1.3%</li>
                                            <li>HDFC Visa/Rupay 2%</li>
                                            <li>Mastercards 2.75%</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b style={{ fontWeight: "600" }}>
                                            Cashfree{" "}
                                            {userId == "1111111"
                                                ? "pg2"
                                                : "Education"}{" "}
                                            Charges(Including Corporate Cards):
                                        </b>
                                        <ul>
                                            <li>Visa/Mastercards/Rupay 1.7%</li>
                                            <li>HDFC 2%</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            {/*   <div className={"radio_wrapp_cash"}>
                                <div className="radio_btn">
                                    <input
                                        checked={PaymentGateWay == 1}
                                        type="radio"
                                        id="razoyPay"
                                        name="Gateway"
                                        value="razoyPay"
                                        onChange={() => setGateway(1)}
                                    />
                                     {" "}
                                    <label htmlFor="razoyPay">Razory Pay</label>
                                </div>

                                <div className="radio_btn">
                                    <input
                                        checked={PaymentGateWay == 2}
                                        type="radio"
                                        id="cashFree"
                                        name="Gateway"
                                        value="cashFree"
                                        onChange={() => setGateway(2)}
                                    />
                                      <label htmlFor="cashFree">Cashfree</label>
                                </div>

                             
                                    <div className="radio_btn">
                                        <input
                                            checked={PaymentGateWay == 3}
                                            type="radio"
                                            id="cashFree1"
                                            name="Gateway"
                                            value="cashFree1"
                                            onChange={() => setGateway(3)}
                                        />
                                         {" "}
                                        <label htmlFor="cashFree1">
                                            Cashfree Edu
                                        </label>
                                    </div> 
                            </div> */}
                            {PaymentGateWay == 1 ? (
                                <div className="add-btn-div">
                                    <Razor
                                        passBlc={addBlc}
                                        submitenabler={submitenabler}
                                        type={paymentType}></Razor>
                                </div>
                            ) : (
                                <div
                                    className="add-btn-div"
                                    style={{ justifyContent: "center" }}>
                                    <button
                                        type="submit"
                                        id="renderBtn"
                                        className="cashfreebutton"
                                        style={{
                                            display: "grid",
                                            alignItems: "center",
                                            justifyItems: "center",
                                        }}
                                        disabled={
                                            PaymentGateWay == 1 || submitenabler
                                        }
                                        onClick={doPayment}>
                                        ADD
                                    </button>
                                </div>
                            )}
                            {/* 
                            {(paymentType == "payu" || paymentType == "") && (
                                <div
                                    className="add-btn-div"
                                    style={{
                                        justifyContent: "center",
                                        marginLeft: "10px",
                                    }}>
                                    <button
                                        onClick={
                                            paymentType == "payu"
                                                ? submithand
                                                : disablehandler
                                        }
                                        disabled={submitenabler}
                                        style={{
                                            background: "#010080",
                                            borderRadius: "20px",
                                            border: "none",
                                            color: "#ffffff",
                                            width: "150px",
                                            fontFamily: "Poppins",
                                            fontSize: "20px",
                                            margin: "auto",
                                            height: "42px",
                                        }}>
                                        Add
                                    </button>
                                </div>
                            )} */}
                            {addBlc && isNaN(addBlc) && (
                                <p className="error-text">
                                    Only Numbers Allowed
                                </p>
                            )}
                            {/* <div className="ip-inr-main">

                                <textarea className="add-blc-notes" name="amount" id="amount"
                                    placeholder="Add Notes" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Deposits;
