import React, { useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from "./components/HomePage_updated/index";
import About from "./About";
import Service from "./Service";
import Contact from "./Contact";
import { Routes, Route } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import "./App.css";
import Dashboard from "./components/dashboard/dashboard";
import WalletTransactions from "./components/dashboard/walletTransactions";
import Deposits from "./components/dashboard/deposits";
import DashboardRight from "./components/dashboard/dashboardRight";
import PayBills from "./components/dashboard/payBills";
import Transactions from "./components/dashboard/transactions";
import Profile from "./components/dashboard/profile";
import WalletTransfer from "./components/dashboard/walletTransfer";
import Kyc from "./components/dashboard/kyc";
import PayWithoutSignin from "./components/dashboard/payWithoutSignin";
import PaymentLinks from "./components/dashboard/paymentLinks";
import PayoutTransactions from "./components/dashboard/payoutTransactions";
import KycPending from "./components/dashboard/kycPending";
import TermsAndConditions from "./termsAndConditions";
import PrivacyPolicy from "./privacyPolicy";
import Refund from "./refundpolicy";
import DServices from "./components/dashboard/services";
import BillPayments from "./components/dashboard/billPayment";
import BillPaymentcomplaint from "./components/dashboard/billpaymentComplaints";
import BillpaymentComplaintStatus from "./components/dashboard/billpaymentComplaintStatus";
import BillpaymentComplaintSearch from "./components/dashboard/billpaymentComplaintSearch";
import BillTranscations from "./components/dashboard/billpaymentTransactions";
import BillpaymnetBroadband from "./components/dashboard/billPaymentBroadBand";
import BillpaymentFetch from "./components/dashboard/billPaymentFetch";
import Billpaymentamount from "./components/dashboard/billpaymentamount";
import BillpaymentSuccess from "./components/dashboard/billPaymentsuccess";
import Insurance from "./components/dashboard/Insurance";
import QuickPay from "./components/dashboard/quickpay";
import QuickPayDetails from "./components/dashboard/quickpaydetails";
import Quickpayamount from "./components/dashboard/quickpayAmount";
import QuickPaySuccess from "./components/dashboard/quickpaySuccess";
import BillPaymentComplaintStatusFetch from "./components/dashboard/billPaymentComplaintStatusFetch";
import Billpaymentcomplaintregistersuccess from "./components/dashboard/billpaymentcomplaintregistersuccess";
import BillpaymentSearchtransactionresult from "./components/dashboard/billpaymentSearchtransactionresult";
import LoadSucess from "./components/dashboard/loadSucess";
import Loadfailed from "./components/dashboard/loadfail";
import Commissions from "./components/dashboard/Commissions";
import { ToastContainer } from "react-toastify";
import TrasactionStatus from "./components/dashboard/LoadWalletSuccess";
import RentPay from "./components/dashboard/rent_pay";
import CardPayments from "./components/dashboard/CardPayments";
import AddCard from "./components/dashboard/Addsavedcard";
function App() {
    useEffect(() => {
        Aos.init({});
    }, []);

    return (
        <>
            {/* <NavBar/> */}
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard/rent-pay" element={<RentPay />} />
                <Route path="/about" element={<About />} />
                <Route path="/service" element={<Service />} />
                <Route
                    path="/dashboard/card-payments"
                    element={<CardPayments />}
                />
                <Route
                    path="/dashboard/saved-cards/add"
                    element={<AddCard />}
                />
                <Route
                    path="/transaction-status"
                    element={<TrasactionStatus />}
                />
                <Route path="/contact" element={<Contact />} />
                <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/refund-policy" element={<Refund />} />
                <Route path="/pay/:recId" element={<PayWithoutSignin />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {/* <Route path="/dashboard/" element={<DashboardRight />} /> */}
                <Route path="/dashboard/deposits" element={<Deposits />} />
                <Route path="/dashboard/pay-bills" element={<PayBills />} />
                <Route
                    path="/dashboard/wallet-transfer"
                    element={<WalletTransfer />}
                />
                <Route
                    path="/dashboard/transactions"
                    element={<Transactions />}
                />
                <Route
                    path="/dashboard/payouts"
                    element={<PayoutTransactions />}
                />
                <Route path="/dashboard/profile" element={<Profile />} />
                <Route path="/dashboard/kyc" element={<Kyc />} />
                <Route
                    path="/dashboard/payment-links"
                    element={<PaymentLinks />}
                />
                <Route path="/dashboard/kyc-pending" element={<KycPending />} />
                <Route path="/dashboard/services" element={<DServices />} />
                <Route
                    path="/dashboard/bill-payments"
                    element={<BillPayments />}
                />
                <Route
                    path="/dashboard/bill-payments/complaints"
                    element={<BillPaymentcomplaint />}
                />
                <Route
                    path="/dashboard/bill-payments/check-status"
                    element={<BillpaymentComplaintStatus />}
                />
                <Route
                    path="/dashboard/bill-payments/search-complaint"
                    element={<BillpaymentComplaintSearch />}
                />
                <Route
                    path="/dashboard/bill-payments/billpaymentTransaction"
                    element={<BillTranscations />}
                />
                <Route
                    path="/dashboard/bill-payments/paybill"
                    element={<BillpaymnetBroadband />}
                />
                <Route
                    path="/dashboard/bill-payments/paybill-fetch/:type"
                    element={<BillpaymentFetch />}
                />
                <Route
                    path="/dashboard/bill-payments/paybill-amount/:type"
                    element={<Billpaymentamount />}
                />
                <Route
                    path="/dashboard/bill-payments/paybill-success/:type"
                    element={<BillpaymentSuccess />}
                />
                <Route
                    path="/dashboard/bill-payments/insurance"
                    element={<Insurance />}
                />
                <Route
                    path="/dashboard/bill-payments/quick-pay"
                    element={<QuickPay />}
                />
                <Route
                    path="/dashboard/bill-payments/quick-pay/details"
                    element={<QuickPayDetails />}
                />
                <Route
                    path="/dashboard/bill-payments/quick-pay/Pay"
                    element={<Quickpayamount />}
                />
                <Route
                    path="/dashboard/bill-payments/quick-pay/QuickPaySuccess"
                    element={<QuickPaySuccess />}
                />
                <Route
                    path="/dashboard/bill-payments/billPaymentComplaintStatusFetch"
                    element={<BillPaymentComplaintStatusFetch />}
                />
                <Route
                    path="/dashboard/bill-payments/billpaymentcomplaintregistersuccess"
                    element={<Billpaymentcomplaintregistersuccess />}
                />
                <Route
                    path="/dashboard/bill-payments/billpaymentSearchtransactionresult"
                    element={<BillpaymentSearchtransactionresult />}
                />
                <Route path="/dashboard/loadSucess" element={<LoadSucess />} />
                <Route path="/dashboard/loadfail" element={<Loadfailed />} />
                <Route
                    path="/dashboard/commissions"
                    element={<Commissions />}
                />
            </Routes>
        </>
    );
}

export default App;
