import React, { useState, useEffect } from "react";
import "./dashboard.css";

import DashboardNav from "./dashboardNav";
import LoginServices from "../../services/LoginServices";
import Billpayment from "./billpaymentNav";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiCurrencyRupee } from "react-icons/hi";
import { useForm } from "react-hook-form";
import { FaUserCircle } from "react-icons/fa";
import Spinner from "../spinner";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function BillpaymentBills() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [spinner, setspinner] = useState(false);
    const [billervalues, setbillervalues] = useState<any>();

    const [refreshP, setRefreshP] = useState<any>("");
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const formdata = watch();
    const userId = localStorage.getItem("userid");
    const Navigate = useNavigate();
    const BillpayHandler = (e: any) => {
        if (e.currentTarget.id == "Insurance") {
            Navigate("/dashboard/bill-payments/insurance");
            return;
        }
        Navigate(
            `/dashboard/bill-payments/paybill-fetch/${e.currentTarget.id}`
        );
    };

    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        let body = {
            billerId: sessionStorage.getItem("Biller"),
        };
        LoginServices.FetchBillerInfoPost(body, config)
            .then((res) => {
                if (res?.data?.Status == 1)
                    setbillervalues(res?.data?.BillerInfo);
                localStorage.setItem(
                    "billerAdhoc",
                    res?.data?.BillerInfo?.billerAdhoc
                );
                localStorage.setItem(
                    "billerName",
                    res?.data?.BillerInfo?.billerName
                );
                console.log(res, "res");
            })
            .catch((err) => {
                alert("something went wrong please try again later");
            });
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            Navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const fetchbillHandler = (data: any) => {
        sessionStorage.setItem("usernumber", data?.MobileNumber);
        const input = Object.entries(data).map((item: any) => {
            return { paramName: item[0], paramValue: item[1] };
        });
        // input.splice(0, -2);
        const bodydata = input.filter(
            (item: any) => item.paramName !== "Amount"
        );
        const bodydatafilter = bodydata.filter(
            (item: any) => item.paramName !== "MobileNumber"
        );
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        let id = {};
        const body = {
            billerAdhoc: "true",
            Amount: Number(data?.Amount) * 100,
            billerId: sessionStorage.getItem("Biller"),
            mobileNumber: data?.MobileNumber,
            Params: {
                inputParams: {
                    input: [...bodydatafilter],
                },
                paymentMethod: {
                    paymentMode: "Wallet",
                    quickPay: "Y",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(data?.Amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "WalletName",
                            infoValue: "PayMark",
                        },
                        {
                            infoName: "MobileNo",
                            infoValue: localStorage.getItem("mobilenumber"),
                        },
                    ],
                },
            },
        };
        LoginServices.quickpaybill(body, config)
            .then((response: any) => {
                setspinner(false);
                if (response.data?.Status == 1) {
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.data?.QuickBillPayResponse)
                    );
                    Navigate(
                        `/dashboard/bill-payments/quick-pay/QuickPaySuccess`
                    );
                } else if (response?.data.status == 0) {
                    alert(response?.data?.Message);
                }
                console.log(response, "1111");
            })
            .catch((err) => {
                setspinner(false);
                alert("some thing went wrong try after sometime");
            });
        //mobilenumber
    };
    return (
        <>
            {spinner && <Spinner />}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Bill Payments</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div style={{ display: "grid", gap: "0rem" }}>
                    <div
                        style={{
                            background: "#F6F7FF",
                            padding: "1rem 0rem",
                        }}>
                        <div className="payment-wrapper">
                            <Billpayment active="QuickPay" />
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#f6f7ff" }}>
                        <div className="biller-details-wrapper">
                            <div>
                                <form
                                    className="biller-details"
                                    onSubmit={handleSubmit(fetchbillHandler)}>
                                    <h1 className="biller-name">
                                        {billervalues?.billerName}
                                    </h1>
                                    {billervalues?.billerInputParams?.paramInfo?.map(
                                        (data: any) => {
                                            const name = data?.paramName;
                                            const boolValue =
                                                data?.isOptional == "true";
                                            // console.log(name);
                                            return (
                                                <div className="biller-detail">
                                                    <input
                                                        className={
                                                            errors[`${name}`] &&
                                                            "input-error"
                                                        }
                                                        data-regex={data?.regEx}
                                                        minLength={
                                                            data?.minLength
                                                        }
                                                        maxLength={
                                                            data?.maxLength
                                                        }
                                                        data-name={
                                                            data?.paramName
                                                        }
                                                        id="billerinput"
                                                        placeholder={name}
                                                        type={"text"}
                                                        pattern={data?.regEx}
                                                        {...register(
                                                            `${name}`,
                                                            {
                                                                required:
                                                                    !boolValue,
                                                                pattern: {
                                                                    value: data?.regEx,
                                                                    message: `invalid input`,
                                                                },
                                                            }
                                                        )}
                                                    />
                                                    {errors[`${name}`] && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                            }}>
                                                            Enter a Valid{" "}
                                                            {data?.paramName}
                                                        </p>
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                    <div className="biller-detail">
                                        <img
                                            src="/assets/img/ruppee.svg"
                                            className="call-icon"
                                            alt=""
                                        />
                                        <input
                                            className={
                                                errors?.Amount && "input-error"
                                            }
                                            id="billerinput"
                                            placeholder="Enter Amount"
                                            type={"number"}
                                            {...register("Amount", {
                                                required: "Please Enter Amnout",
                                            })}
                                        />
                                        {errors?.Amount && (
                                            <p style={{ color: "red" }}>
                                                {" "}
                                                {errors?.Amount.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className="biller-detail">
                                        <img
                                            src="/assets/img/call.svg"
                                            className="call-icon"
                                            alt=""
                                        />
                                        <input
                                            className={
                                                errors?.MobileNumber &&
                                                "input-error"
                                            }
                                            id="billerinput"
                                            placeholder="Enter Mobile Number"
                                            type={"number"}
                                            {...register("MobileNumber", {
                                                required:
                                                    "Please Enter Mobile Number",
                                            })}
                                        />
                                        {errors?.MobileNumber && (
                                            <p style={{ color: "red" }}>
                                                {" "}
                                                {errors?.MobileNumber.message}
                                            </p>
                                        )}
                                    </div>
                                    <button type="submit">Pay</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default BillpaymentBills;
