import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
// import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BillpaymentComplaintSubmenu from "./BillpaymentComplaintSubmenu";
import Spinner from "../spinner";
// const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: "#010080";
// `;

function KycPending() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const [spinner, setspinner] = useState<any>(false);

    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");

    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'
    const onSubmit = (data: any) => {
        setspinner(true);
        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        let body = {
            ...data,
            complaintType: "Transaction",
            mobileNumber: localStorage.getItem("mobilenumber"),
        };
        console.log(data);

        LoginServices.ComplaintRegister(body, config)
            .then((res) => {
                setspinner(false);
                if (res?.data?.Status == 1) {
                    sessionStorage.setItem(
                        "registersuccess",
                        JSON.stringify(res?.data?.RegisterComplaintResponse)
                    );
                    navigate(
                        "/dashboard/bill-payments/billpaymentcomplaintregistersuccess"
                    );
                } else if (res?.data?.Status == 0) {
                    alert(res?.data?.Message);
                }
                console.log(res);
            })
            .catch((err) => {
                alert("something went wrong try later");
                setspinner(false);
            });
    };
    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2>Complaint</h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="Complaint-wrapper">
                    <div className="Complaint-Nav">
                        <BillpaymentNav active="complaints" />
                    </div>
                    <div className="Complaint-submenuWrapper">
                        <BillpaymentComplaintSubmenu active={"raise"} />
                        <div className="Complaint-formwrapper">
                            <h1>BBPS Raise Complaint</h1>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="TypeOfComplaint-wrapper">
                                    <label htmlFor="TypeOfComplaint">
                                        Type of Transaction
                                    </label>
                                    <div className="complaint-type">
                                        <input type="radio" defaultChecked />
                                        <p>Transaction</p>
                                    </div>
                                </div>
                                <div className="Complaint-form-control">
                                    <label htmlFor="Transaction Ref ID">
                                        Transaction Ref ID
                                    </label>
                                    <input
                                        className={`input ${
                                            errors.transactionReferenceId &&
                                            "inputerror"
                                        }`}
                                        type="text"
                                        {...register("transactionReferenceId", {
                                            required:
                                                "transactionReferenceId is required",
                                        })}
                                    />
                                    {errors?.transactionReferenceId && (
                                        <p className="error">
                                            {
                                                errors?.transactionReferenceId
                                                    .message
                                            }
                                        </p>
                                    )}
                                </div>
                                <div className="Complaint-form-control">
                                    <label htmlFor="Complaint Disposition ">
                                        Complaint Disposition
                                    </label>
                                    <select
                                        className={`select ${
                                            errors?.complaintDisposition &&
                                            "inputerror"
                                        }`}
                                        {...register("complaintDisposition", {
                                            required:
                                                "complaintDisposition is required",
                                        })}>
                                        <option
                                            value="Transaction Successful, account not updated
">
                                            Transaction Successful, account not
                                            updated
                                        </option>
                                        <option value="Amount deducted, biller account credited but transaction ID not received">
                                            Amount deducted, biller account
                                            credited but transaction ID not
                                            received
                                        </option>
                                        <option
                                            value="Amount deducted, biller account not credited & transaction ID not receivedAmount deducted multiple times
">
                                            Amount deducted, biller account not
                                            credited & transaction ID not
                                            received
                                        </option>
                                        <option
                                            value="Amount deducted multiple times
">
                                            Amount deducted multiple times
                                        </option>
                                        <option value="Double payment updated">
                                            Double payment updated
                                        </option>
                                        <option
                                            value="Erroneously paid in wrong account
">
                                            Erroneously paid in wrong account
                                        </option>
                                        <option value="Others, provide details in description">
                                            Others, provide details in
                                            description
                                        </option>
                                    </select>
                                    {errors?.complaintDisposition && (
                                        <p className="error">
                                            {
                                                errors?.complaintDisposition
                                                    .message
                                            }
                                        </p>
                                    )}
                                </div>

                                <div className="Complaint-form-control">
                                    <label htmlFor="Description">
                                        Description
                                    </label>
                                    <textarea
                                        className={`textarea ${
                                            errors?.complaintDescription &&
                                            "inputerror"
                                        }`}
                                        {...register("complaintDescription", {
                                            required:
                                                "complaintDescription is required",
                                        })}></textarea>
                                    {errors?.complaintDescription && (
                                        <p className="error">
                                            {
                                                errors?.complaintDescription
                                                    .message
                                            }
                                        </p>
                                    )}
                                </div>
                                <div className="button">
                                    <button>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
