import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    const { type } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    let [loading, setLoading] = useState(false);
    const [successdetails, setsuccessdetails] = useState<any>();
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    const success = sessionStorage.getItem("success");
    const billername = sessionStorage.getItem("billerName");
    const billerid = sessionStorage.getItem("billerid");
    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");
    useEffect(() => {
        if (success) {
            setsuccessdetails(JSON.parse(success));
            console.log(JSON.parse(success));
        }
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]);
    // showSection == 'biller-list'

    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper">
                        <div className="payment-header">
                            <div className="header-wrapper">
                                <h2>Load Wallet</h2>
                                <img
                                    style={{ alignSelf: "center" }}
                                    src="/assets/img/arrow.svg"
                                    alt=""
                                />
                                <h3>{type}</h3>
                            </div>
                            <img
                                className="payment-header-icon"
                                src="assets/billpayment/billpayment_header.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="successWrappe">
                        <div className="successWrapper">
                            <img src="assets/img/assured.svg" alt="" />
                            <p>Your Transaction is Successfull..!</p>
                            <div className="success-details">
                                <div className="success-detail">
                                    <p>Order ID</p>
                                    <p>:</p>
                                    <p>{searchParams.get("order_id")}</p>
                                </div>
                                <div className="success-detail">
                                    <p>Amount</p>
                                    <p>:</p>
                                    <p>{searchParams.get("payment_amount")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
