import React, { useState, useEffect, useRef } from "react";
import LoginServices from "../../services/LoginServices";
import CardClasses from "./Saved.module.css";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { MdVerified } from "react-icons/md";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardNav from "./dashboardNav";

interface FormData {
    cardNumber: string;
    cardHolderName: string;
    issuerBank: string;
    cardLevel: string;
    cardType: string;
    cardNetwork: string;
    mobileNumber: any;
    ConfirmcardNumber: string;
}

function SavedCard() {
    const navigate = useNavigate();
    const [walletBlc, setWalletBlc] = useState<any>(0);
    const userId = sessionStorage.getItem("userid");
    const [loading, setLoading] = useState(false);
    const [userIpAddress, setUserIpAddress] = useState("");

    const [verified, SetisVerified] = useState(false);
    const [CardDetails, setCardDetails] = useState({});
    const [formattedValue, setFormattedValue] = useState<string>("");
    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        resetField,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<FormData>();
    const watchData = watch();
    const [refreshP, setRefreshP] = useState<any>("");
    // const [] = useState([]);
    const componentMounted = useRef(true);
    useEffect(() => {
        if (sessionStorage.getItem("mobilenumber")) {
            setValue("mobileNumber", sessionStorage.getItem("mobilenumber"));
        }
        console.log(sessionStorage.getItem("mobilenumber"));
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    // console.log(response);

                    if (response.data.Status == 1 && componentMounted.current) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
        return () => {
            // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        };
    }, [refreshP]);
    let VerifyCard = () => {
        if (watchData?.cardNumber && watchData?.cardNumber.length == 16) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.VerifyCard(
                {
                    cardNumber: (watchData?.cardNumber.match(/\d/g) || [])
                        .slice(0, 6)
                        .join(""),
                    ipAddress: userIpAddress,
                    latitude: 0,
                    longitude: 0,
                },
                config
            )
                .then((response: any) => {
                    setLoading(false);
                    console.log(response?.data?.CardBinResponse);
                    if (
                        response.data.Status == 1 &&
                        response?.data?.CardBinResponse
                    ) {
                        console.log(response?.data?.CardBinResponse);
                        SetisVerified(true);
                        setValue(
                            "cardLevel",
                            response?.data?.CardBinResponse?.cardLevel
                        );
                        setValue(
                            "cardNetwork",
                            response?.data?.CardBinResponse?.cardNetwork
                        );
                        setValue(
                            "cardType",
                            response?.data?.CardBinResponse?.cardType
                        );
                        setValue(
                            "issuerBank",
                            response?.data?.CardBinResponse?.issuerBank
                        );
                    } else {
                        setError("cardNumber", {
                            message: "Invalid Card Number",
                        });
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);
                });
        }
    };
    const onSubmit: SubmitHandler<FormData> = (data) => {
        if (!verified) {
            setError("cardNumber", { message: "Invalid Card Number" });
            return;
        }
        if (data.cardNumber != data.ConfirmcardNumber) {
            setError("ConfirmcardNumber", { message: "Card Number Mismatch" });
            return;
        }
        setLoading(true);

        let config = {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
        };
        LoginServices.AddCard(
            {
                ...data,
                ipAddress: userIpAddress,
                latitude: 0,
                longitude: 0,
                mobileNumber: sessionStorage.getItem("mobilenumber"),
            },
            config
        )
            .then((response: any) => {
                setLoading(false);
                console.log(response?.data?.CardBinResponse);
                if (response.data.Status == 1) {
                    toast.success("Card Added Successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    navigate(-1);
                } else {
                    toast.warning(response?.data?.Message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                // console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const handleInputChange = (e: any) => {
        const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
        if (value.length <= 16) {
            setFormattedValue(value);
            clearErrors("cardNumber");
            if (verified) {
                SetisVerified(false);
                resetField("cardHolderName");
                resetField("cardLevel");
                resetField("cardNetwork");
                resetField("cardType");
                resetField("issuerBank");
            }
        }
    };

    const formatValue = (value: string): string[] => {
        const parts: string[] = [];
        for (let i = 0; i < value.length; i += 4) {
            parts.push(value.slice(i, i + 4));
        }
        return parts;
    };
    return (
        <div className="main-bg">
            {loading && <Loader />}
            <div>
                <DashboardNav active={"cardPayments"}></DashboardNav>
            </div>
            <div>
                {/*  <header
                    className={CardClasses["row_header"]}
                    style={{
                        flexDirection: "column",
                        gap: "1rem",
                        margin: "1rem 2rem",
                    }}>
                    <button
                        onClick={() => navigate(-1)}
                        style={{ width: "fit-content" }}>
                        Back
                    </button>
                    <h3>Add Card</h3>
                </header> */}
                <div
                    className={CardClasses["Card_wrapper"]}
                    style={{
                        justifyContent: "center",
                        // flexDirection: "column",
                        gap: "1rem",
                        background: "white",
                    }}>
                    <div className={CardClasses["container"]}>
                        <div
                            className={CardClasses["card"]}
                            style={{ width: "360px", height: "200px" }}>
                            <div className={CardClasses["card-inner"]}>
                                <div className={CardClasses["front"]}>
                                    <img
                                        src="assets/map.png"
                                        className={CardClasses["map-img"]}
                                    />
                                    <div className={CardClasses["row"]}>
                                        <img
                                            src="assets/chip.png"
                                            width="60px"
                                        />
                                        <p>{watchData?.issuerBank}</p>
                                        {watchData.cardNetwork == "VISA" && (
                                            <img
                                                src="assets/visa.png"
                                                width="60px"
                                            />
                                        )}
                                        {watchData.cardNetwork &&
                                            watchData.cardNetwork != "VISA" && (
                                                <img
                                                    src="assets/mastercard.png"
                                                    width="60px"
                                                />
                                            )}
                                    </div>
                                    <div
                                        className={`${CardClasses["row"]} ${CardClasses["card-no"]}`}
                                        style={{
                                            gap: "1rem",
                                            justifyContent: "flex-start",
                                        }}>
                                        {formattedValue.length == 0 ? (
                                            <>
                                                <p style={{ fontSize: "27px" }}>
                                                    xxxx
                                                </p>
                                                <p style={{ fontSize: "27px" }}>
                                                    xxxx
                                                </p>
                                                <p style={{ fontSize: "27px" }}>
                                                    xxxx
                                                </p>
                                                <p style={{ fontSize: "27px" }}>
                                                    {" "}
                                                    xxxx
                                                </p>
                                            </>
                                        ) : (
                                            formatValue(formattedValue).map(
                                                (chunk, index) => (
                                                    <p
                                                        style={{
                                                            fontSize: "27px",
                                                        }}
                                                        key={index}>
                                                        {chunk}
                                                    </p>
                                                )
                                            )
                                        )}
                                    </div>
                                    <div
                                        className={`${CardClasses["row"]} ${CardClasses["card-holder"]}`}>
                                        <p>CARD HOLDER</p>
                                        <p>MOBILE</p>
                                    </div>
                                    <div
                                        className={`${CardClasses["name"]} ${CardClasses["row"]}`}>
                                        <p>
                                            {watchData?.cardHolderName
                                                ? watchData?.cardHolderName
                                                : "xxxxxxxxx"}
                                        </p>

                                        <p>
                                            {watchData?.mobileNumber
                                                ? watchData?.mobileNumber
                                                : "XXXXXXXXXX"}
                                        </p>
                                    </div>
                                </div>
                                {/*    <div className="back">
                                    <img
                                        src="https://i.ibb.co/PYss3yv/map.png"
                                        className="map-img"
                                    />
                                    <div className="bar"></div>
                                    <div className="row card-cvv">
                                        <div>
                                            <img src="https://i.ibb.co/S6JG8px/pattern.png" />
                                        </div>
                                        <p>824</p>
                                    </div>
                                    <div className="row card-text">
                                        <p>
                                            this is a virtual card design using
                                            HTML and CSS. You can aslo design
                                            something like this.
                                        </p>
                                    </div>
                                    <div className="row signature">
                                        <p>CUSTOMER SIGNATURE</p>
                                        <img
                                            src="https://i.ibb.co/WHZ3nRJ/visa.png"
                                            width="80px"
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h3>Card Details</h3>
                        <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">Card Number</label>
                            <div className={CardClasses["input"]}>
                                <div>
                                    {verified && (
                                        <MdVerified
                                            className={CardClasses["verified"]}
                                        />
                                    )}

                                    <Controller
                                        name="cardNumber"
                                        control={control}
                                        rules={{
                                            required: "Card Number is required",
                                        }}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                type="password"
                                                maxLength={16}
                                                placeholder="Enter 16 digits Card Number"
                                                onBlur={VerifyCard}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleInputChange(e);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            {errors?.cardNumber && (
                                <p style={{ color: "red", marginBottom: 0 }}>
                                    {errors?.cardNumber.message}
                                </p>
                            )}
                        </div>
                        <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">
                                Confirm Card Number
                            </label>
                            <div className={CardClasses["input"]}>
                                <Controller
                                    name="ConfirmcardNumber"
                                    control={control}
                                    rules={{
                                        required: "Card Number is required",
                                    }}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            maxLength={16}
                                            placeholder="Confirm Card Number"
                                        />
                                    )}
                                />
                            </div>
                            {errors?.ConfirmcardNumber && (
                                <p style={{ color: "red", marginBottom: 0 }}>
                                    {errors?.ConfirmcardNumber.message}
                                </p>
                            )}
                        </div>
                        <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">Card Holder Name</label>

                            <input
                                type="text"
                                {...register("cardHolderName", {
                                    required: verified
                                        ? "Name is required"
                                        : false,
                                })}
                            />
                            {errors?.cardHolderName && (
                                <p style={{ color: "red", marginBottom: 0 }}>
                                    {errors?.cardHolderName.message}
                                </p>
                            )}
                        </div>
                        <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">Mobile Number</label>

                            <input
                                type="text"
                                {...register("mobileNumber", {
                                    disabled: true,

                                    required: false,
                                })}
                            />
                        </div>
                        {/*   <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">Issuer Bank</label>

                            <input {...register("issuerBank")} readOnly />
                        </div>
                        <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">Card Level</label>

                            <input {...register("cardLevel")} readOnly />
                        </div>
                        <div className={CardClasses["form_control"]}>
                            <label htmlFor="cardNumber">Card Network</label>

                            <input {...register("cardNetwork")} readOnly />
                        </div> */}
                        <p
                            style={{
                                color: "red",
                                width: "100%",
                                maxWidth: "29rem",
                            }}>
                            Note :You can pay only KYC holder credit card
                            details,Bill payment to other cards will be rejected
                        </p>
                        <button disabled={!verified}>Add Card</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SavedCard;
