import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from './dashboardNav';
import './dashboard.css';
import WalletBlcHeader from './walletBlcHeader';
import LoginServices from '../../services/LoginServices';
import Razor from './Razor';
import { Modal, Row, Col } from 'react-bootstrap';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { PulseLoader } from 'react-spinners';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: '#010080';
`;

function WalletTransfer() {
    let [loading, setLoading] = useState(false);
    let [loading1, setLoading1] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>('');
    const { register, formState: { errors }, handleSubmit, reset } = useForm();


    const [walletBlc, setWalletBlc] = useState<any>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPayee, setCurrentPayee] = useState<any>();
    const [userDetails, setUserDetails] = useState<String>();
    const [payAmount, setPayAmount] = useState<any>();
    const [payNotes, setPayNotes] = useState<any>();


    const [addBlc, setAddBlc] = useState<number>();
    const userId = localStorage.getItem('userid');
    const [payoutList, setPayoutList] = useState<any>();


    const [showSection, setShowSection] = useState<string>('biller-list');
    const [searchItem, setSearchItem] = useState<string>('');
    const [usersList, setUsersList] = useState<any>();


    useEffect(() => {
        if (userId) {

            let config = {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance)
                    }
                    else {

                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);

                });

        } else {
            alert('Login to see your dashboard')
            navigate('/')
        }

    }, [refreshP == 'yes']);
    // showSection == 'biller-list'
    function isNumber(n: any) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
    const searchUser = () => {
        console.log(searchItem);
        if (userId) {

            const payload: any = {}
            if (isNumber(searchItem)) {
                payload.mobileNumber = searchItem;
            } else {
                payload.name = searchItem;
            }
            console.log(payload);
            let config = {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };

            setLoading1(true);

            LoginServices.searchUserByNameOrMobile(payload, config)
                .then((response: any) => {
                    setLoading1(false);
                    console.log(response);
                    if (response.data.Status == 1) {
                        // window.location.reload();
                        setPayoutList(response?.data?.WalletContacts)
                    }
                    else {
                        alert(response.data.Message)
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading1(false);

                });
        }
    }

    const addWalletContact = () => {
        console.log(userDetails);
        // if(userDetails)
        if (!userDetails?.length) {
            alert('Payee User id not found');
            return
        }
        if (userDetails == userId) {
            alert("You cann't add your self as payer");
            return
        }
        const payload = {
            userId: userId,
            contact_userId: userDetails
        }
        let config = {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        };

        LoginServices.createWalletPayContact(payload, config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    alert(response.data.Message);
                    setShowSection('biller-list')
                    setRefreshP('yes')
                    reset()
                }
                else {
                    alert(response.data.Message);
                }
                console.log(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const sendPayment = (item: any) => {
        if (userId) {
            console.log(item);

            if (payAmount > walletBlc) {
                alert('Pay amount is more than your wallet balance')
                return
            }
            if (payAmount > 200000) {
                alert('Pay amount is more than 2,00,000Rs')
                return
            }
            const payload = {
                mobileNumber: item?.mobileNumber,
                amount: parseInt(payAmount),
                notes: payNotes
            }
            let config = {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };
            setLoading(true);
            LoginServices.payToWallet(payload, parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        // alert(response?.data?.Message);
               
                        // window.location.reload();
                        setLoading(false);
                        // showSection('biller-list')
                        setRefreshP('yes')
                        setShowModal(false)
                        // alert(response?.data?.Message);
                        toast.success('Transaction completed!', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                    }
                    else {
                        setLoading(false);
                        alert(response?.data?.Message);

                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                    setLoading(false);


                });
        }
    }

    return (
        <>

            <div className="main-bg1">
                <div>
                    <DashboardNav active={"wallet-transfer"}></DashboardNav>
                </div>
                <div >
                    {showSection == 'add-img' &&
                        <div>
                            <p className="deposit-head">Wallet Transfer</p>
                            <div className="add-img-w">
                                {/* <div className="pay-bills-grp1 mar-top-50">
                                <p className="pay-bills-head"></p>
                                <div className="d-flex">
                                    <button onClick={() => setShowSection('biller-list')} className="see-billers-btn">See List</button>
                                </div>
                            </div> */}
                                <div className="pay-w-bills-center">
                                    <div>
                                        <div>
                                            <img className="pay-bills-main-img" src="assets/img/wallet-pay.png" alt="" />
                                            <p className="add-more-p">Make easy Payment by adding Payer</p>
                                        </div>
                                    </div>
                                    <div>

                                        <div className="add-btn-div1">
                                            <button onClick={() => setShowSection('add-form')} className="add-biller-btn">Add Payer</button>
                                            {/* onClick={loginFormSubmit} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {showSection == 'biller-list' &&

                        <div>
                            {/* <p className="deposit-head">Wallet Transfer</p> */}

                            <div className="pay-bills-grp1">
                                <p className="deposit-head">Wallet Transfer</p>
                                {/* <input className="search-payers" placeholder="search with name or mobile number" /> */}
                                {/* <div className="d-flex">
                                    <button onClick={() => setShowSection('add-form')} className="see-billers-btn">Add Payer</button>
                                </div> */}
                            </div>

                            <div className="grid1-main-w">
                                {/* <input className="search-payers" placeholder="search with name or mobile number" /> */}
                                <div className="d-flex">
                                    <input
                                        onKeyDown={(e) => {
                                            if (e.key == "Enter") {
                                                searchUser()
                                            }
                                        }}
                                        onChange={(e: any) => setSearchItem(e.target.value)} name="name" id="name" className="pay-bill-ip1" type="text" placeholder="Search with Name or Contact Number" />
                                    <button onClick={searchUser}

                                        className="search-user-btn" > Search</button>
                                </div>

                                <PulseLoader color={'#010080'} loading={loading1} css={override} size={20} />

                                <div className="row">
                                    {payoutList?.length != 0 && payoutList?.map((item: any) => {
                                        return (
                                            <div key={item?.bankAccountNumber} className="col-lg-4 col-md-4 payee-col">
                                                <div onClick={() => {
                                                    console.log(item);
                                                    setCurrentPayee(item);
                                                    setShowModal(true);
                                                }} className="payee-col-sub" style={{ cursor: 'pointer' }}>
                                                    {/* <h1 className="first-letter">{item?.firstName.charAt(0)}</h1> */}
                                                    <div className="d-flex card-img-name">

                                                        <div>
                                                            <img className="card-avatar" src="assets/img/user-avatar2.png" alt="" />
                                                        </div>
                                                        <div>
                                                            <p className="ac-name">{item?.firstName}</p>
                                                            <p className="ac-number"> {item?.mobileNumber}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                    {
                                        payoutList?.length == 0 &&
                                        <h2>No Users Found.</h2>
                                    }

                                </div>
                            </div>

                        </div>
                    }

                    {showSection == 'add-form' &&
                        <div>

                            <div className="pay-bills-grp1">
                                <p className="deposit-head">Add Payer</p>
                                {/* <p className="pay-bills-head">Add Payerd</p> */}
                                <div className="d-flex">
                                    <button onClick={() => setShowSection('biller-list')} className="see-billers-btn">See Billers</button>
                                </div>
                            </div>

                            <div className="form-main">
                                <div className="form-sub" style={{ marginBottom: '10px' }}>
                                    <div className="d-flex">
                                        <input
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    searchUser()
                                                }
                                            }}
                                            onChange={(e: any) => setSearchItem(e.target.value)} name="name" id="name" className="pay-bill-ip1" type="text" placeholder="Search with Name or Mobile Number" />
                                        <button onClick={searchUser}

                                            className="search-user-btn" > Search</button>
                                    </div>

                                    <select name="UserID" className="add-user-dropdown"
                                        onChange={(e: any) => {
                                            setUserDetails(e.target.value);
                                            console.log(e.target.value);
                                        }}
                                        style={{
                                            height: "35px", width: "100%"
                                        }}>
                                        <option className="bg-white text-dark" selected >Select User</option>
                                        {usersList !== undefined && usersList.map((item: any) => {
                                            return (
                                                <option className="bg-white text-dark" value={item.userId}>{item?.firstName} ({item?.mobileNumber}) </option>
                                            );
                                        })}
                                    </select>
                                    <div className="d-flex" style={{ marginTop: '20px' }}>
                                        <button disabled={userDetails?.length == 0} onClick={addWalletContact} className="add-biller-btn">Add</button>
                                    </div>
                                </div>
                                <form className="form-sub">
                                    {/* onChange={(e:any)=>setAddBlc(e.target.value)} */}
                                    {/* <input {...register("name", { required: true })} name="name" id="name" className="pay-bill-ip" type="text" placeholder="Enter Name" />
                                    <label className="error-text"> {errors?.name && "Name is required"} </label>

                                    <input maxLength={10} {...register("mobileNumber", { required: true, pattern: /^([0-9]{10})+$/i })}
                                        name="mobileNumber" id="mobileNumber" className="pay-bill-ip" type="text" placeholder="Enter Contact Number" />
                                    <label className="error-text">{errors?.mobileNumber?.type == 'required' && "Contact number is required "} {errors?.mobileNumber?.type == 'pattern' && "Mobile Number Invalid"} </label> */}




                                    {/* <div className="d-flex" style={{ marginTop: '20px' }}>
                                        <button type='submit' className="add-biller-btn">Add</button>
                                    </div> */}

                                </form>
                            </div>

                        </div>
                    }


                    <Modal
                        show={showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        dialogClassName="c-modal-pay-bills"
                        onHide={() => setShowModal(false)}
                    >
                        {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
                        <Modal.Body>

                            <div className="m-name">
                                <p className="m-name-payee">{currentPayee?.firstName}</p>
                                <p className="m-mobile-payee">{currentPayee?.mobileNumber}</p>
                                <div className="pay-ip-div">
                                    <input onChange={(e: any) => setPayAmount(e.target.value)} name="amount"
                                        className="pay-ip-div-e1" type="text" autoComplete='off' placeholder="Please Enter Amount" />
                                    {/* <textarea maxLength={450} onChange={(e: any) => setPayNotes(e.target.value)} name="notes"
                                        className="pay-ip-div-e2" placeholder="Add a Note" /> */}
                                    {/* <div onClick={()=>sendPayment(currentPayee)}> */}
                                    {loading == false &&
                                        <img onClick={() => sendPayment(currentPayee)} className="arrow-btn-payee" src="assets/img/arrow-right.png" alt="" />
                                    }
                                    <PulseLoader color={'#010080'} loading={loading} css={override} size={20} />

                                    {/* </div> */}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default WalletTransfer;