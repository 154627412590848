import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import LoginServices from "../services/LoginServices";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import Signupclass from "./Sign.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLock } from "react-icons/fa";

const Login = (props: any) => {
    const [counter, setcounter] = useState(60);

    const [resendenabler, setResendEnabler] = useState(true);
    const navigate = useNavigate();
    const reload = () => window.location.reload();
    const [getOTP, setgetOTP] = useState(false);
    const [errorMsg, setErrorMsg] = useState<String>("");
    let [user, setUser] = useState({
        mobileNumber: "",
    });

    let signIn = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        });
    };
    const sendOtp = () => {
        console.log(user);
        const pay = {
            mobileNumber: user.mobileNumber,
        };
        setErrorMsg("");
        LoginServices.loginWithPhonePassword(pay)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    setResendEnabler(true);
                    setgetOTP(true);
                    const mytimer = setInterval(() => {
                        setcounter((prev) => prev - 1);
                    }, 1000);
                    setTimeout(() => {
                        setcounter(60);
                        setResendEnabler(() => {
                            clearInterval(mytimer);
                            return false;
                        });
                    }, 60000);
                } else {
                    setErrorMsg(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };
    const loginFormSubmit = () => {
        console.log(user);
        setErrorMsg("");
        setgetOTP(true);
        LoginServices.loginWithOtp(user)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    localStorage.setItem(
                        "userid",
                        response?.data?.userDetails?.userId
                    );
                    localStorage.setItem(
                        "userdetails",
                        JSON.stringify(response?.data?.userDetails)
                    );
                    sessionStorage.setItem("token", response?.data?.Token);

                    navigate("/dashboard");
                } else {
                    setErrorMsg(response.data.Message);
                }

                console.log(response.data);
            })
            .catch((e: any) => {
                console.log(e.response);
                setErrorMsg(e?.response?.data?.Message);
            });
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="login-modal1"
            centered
            backdrop={false}>
            <Modal.Body>
                <div className={Signupclass["border"]}>
                    <h3>LOGIN</h3>
                    <button
                        className={Signupclass["close"]}
                        onClick={() => {
                            props.onHide();
                        }}>
                        x
                    </button>
                </div>

                <div className={Signupclass["wrapper"]}>
                    <div className={Signupclass["Container"]}>
                        <p>Welcom Back,Please Enter Your Details</p>
                        <div className={Signupclass["input-wrapper"]}>
                            <input
                                required={true}
                                onChange={signIn}
                                name="mobileNumber"
                                type="number"
                                placeholder="Enter Mobile Number"
                            />
                            <FaPhoneAlt className={Signupclass["icon"]} />
                        </div>
                        {errorMsg && (
                            <span className="text-danger">{errorMsg}</span>
                        )}
                        {getOTP ? (
                            <div className={Signupclass["input-wrapper"]}>
                                <FaLock className={Signupclass["icon"]} />
                                <input
                                    onChange={signIn}
                                    name="OTP"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter OTP"
                                />
                            </div>
                        ) : (
                            <button onClick={() => sendOtp()}>Send OTP</button>
                        )}

                        {getOTP && (
                            <>
                                <button onClick={() => loginFormSubmit()}>
                                    Login
                                </button>
                                <div className={Signupclass["resend"]}>
                                    <button
                                        disabled={resendenabler}
                                        onClick={() => sendOtp()}>
                                        Resend OTP
                                        {resendenabler == true
                                            ? `(${counter})`
                                            : ""}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    <p className={Signupclass["singup_button"]}>
                        Dont Have an account?{" "}
                        <button
                            onClick={() => {
                                props.onHide();
                                props.setsignup(true);
                            }}>
                            Signup
                        </button>
                    </p>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Login;
